<template lang="pug">
	.widget
		el-card.box-card

			.clearfix.d-flex.justify-content-start(slot="header")
				.d-flex.justify-content-between.w-100
					el-col.text-left(:span="24")
						h3
							b Lead Finder
					el-button.v-desktop(v-if="permit(4) && ctf", v-b-tooltip.html.hover.top="", title="Select columns to show", type="warning", size="small",  :icon="`el-icon-view`", @click="toggleTable")
					el-button.v-desktop(v-if="permit(3) && crl" v-b-tooltip.html.hover.top="", :title="`Show Reassign Queue`", :type="`info`", size="small",  :icon="`el-icon-guide`", @click="showQueueListModal=!showQueueListModal")
					el-button.v-desktop(v-if="permit(1) && (filters.source_app === 'IFRAME' || filters.source_app === '' || filters.source_app === 'FLOW')" v-b-tooltip.html.hover.top="", :title="`Show Visits`", :type="`danger`", size="small",  :icon="`el-icon-coin`", @click="showVisitsModal=!showVisitsModal")
					el-button.v-desktop(v-if="permit(1) && filters.source_app === 'API'" v-b-tooltip.html.hover.top="", :title="`Show Requests`", :type="`success`", size="small",  :icon="`el-icon-cpu`", @click="showRequestsModal=!showRequestsModal")
					//     el-button.v-desktop(v-b-tooltip.html.hover.top="", :title="`${refreshLeadFlag!==0 ? 'Refresh Options' : 'Search with filters'}`", :type="`${refreshLeadFlag!==0 ? 'info' : 'primary'}`", size="small",  :icon="`el-icon-${refreshLeadFlag!==0 ? 'refresh' : 'search'}`", @click="refreshData('button')")
					el-button.v-desktop(v-b-tooltip.html.hover.top="", :title="`${refreshLeadFlag!==0 ? 'Refresh List' : 'Search with filters'}`", :type="`${refreshLeadFlag!==0 ? 'info' : 'primary'}`", size="small",  :icon="`el-icon-${refreshLeadFlag!==0 ? 'refresh' : 'search'}`", @click="refreshData('button')")
					el-button.v-desktop(v-if="permit(4) && cdex && !filters.deleted" v-b-tooltip.html.hover.top="", :title="`Download Excel`", :type="`warning`", size="small",  :icon="`el-icon-download`", @click="openSpreadsheetExportPormpt")
					el-button(v-b-tooltip.html.hover.top="", title="Clear Filters", type="info", icon="el-icon-delete", size="small", @click="clearFilters")

			el-form
				el-row(:gutter="10")
					el-col(v-if="permit(3)" :xs="8", :sm="6" :lg="2")
						el-form-item(label-position="top" label-width="auto" v-b-tooltip.html.hover-right="", title="Change the origin to show related filters. Note, FLOW and IFRAME trigger the same ones" style="z-index:1111111")
							template(slot="label")

									span() Origin&nbsp;
										i.el-icon-question.chooseable.no-deco()
							el-select.w-100(filterable="",   size="mini"  v-model='filters.source_app', placeholder='Origin', @change="changeOrigin")
								el-option.text-bold(label='All', value='')
								el-option(v-for="o of origin_options", :label="o.t", :value="o.v")

					el-col(:xs="exactRole(4) ? 24 : 16", :sm="exactRole(4) ? 24 : 12", :lg="exactRole(4) ? 6 : 3")
						el-form-item(label-position="top" label-width="auto" v-b-tooltip.html.hover-right="", title="You can search for multiple entries by adding a comma between the values")
							template(slot="label")
								span() By Keyword&nbsp;
									i.el-icon-question.chooseable.no-deco()
							el-input(type="text", size="mini" placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData")
					el-col(:xs="12", :sm="6", :lg="3" v-if="permit(1)")
						el-form-item(label="Search Params" label-position="top" label-width="auto")
							el-input(type="text", size="mini" placeholder="Search By Keyword...", v-model="filters.params", v-debounce:300ms="fetchData")

					el-col(:xs="12", :sm="12", :lg="4")
						el-form-item(label="Date Range" label-position="top" label-width="auto")
							el-date-picker.w-100(v-model='filters.range', size="mini"  type='daterange', start-placeholder='Start date', :picker-options="pickerOptions"
								end-placeholder='End date', :default-time="['00:00:00', '23:59:59']", value-format="yyyy-MM-dd", @change="fetchData")

					// ======== //

					el-col(:xs="12", :sm="6", :lg="3")
						el-form-item(label="Lead View Types"  multiple="" label-position="top" label-width="auto")
							el-select.w-100(size="mini", filterable="", v-model='filters.type', placeholder='Lead Type', @change="fetchData")
								el-option.text-bold(label='All', value='all')
								el-option(v-for="o of customer_view_types" :label="o.t" :value="o.v")


					el-col(v-if="permitColumn(['brand_id'])" :xs="12",  :sm="6", :lg="3")
						el-form-item(label="Brand" label-position="top" label-width="auto" )
							el-select.w-100(:collapse-tags="true" filterable="", multiple="" size="mini"  v-model='filters.brand', placeholder='By Brand', @change="getFunnelFamiliesByBrand")
								el-option.text-bold(label='All', value="-1" :disabled="filters.brand.length > 0 && filters.brand[0] !== 'all'")
								el-option(v-for="o of brands_options", :label="o.t", :value="o.v" :disabled="filters.brand.length === 1 && filters.brand[0] === 'all'")

					el-col(:xs="12" :sm="6" :lg="3" v-if="cspa && permitColumn(['affiliate_id','params_affiliate_id'], false) && (permit(3) && (filters.source_app === 'IFRAME' || filters.source_app === 'FLOW')) || (exactRole(4) && !sol)")
						el-form-item(label="Affiliate (Param)" label-position="top" label-width="auto")
							el-select.w-100(:collapse-tags="true" filterable="", multiple="" size="mini" v-model="filters.params_affiliate_id", placeholder="Affiliate (Param)", @change="fetchData")
								//el-option.text-bold(label='All', value='')
								el-option(v-for="o of affiliates_options" :label="`${o.t} (${o.n})`" :value="o.v")

					el-col(:xs="12" :sm="6" :lg="3" v-if="permit(3) && csbo && permitColumn(['brand_owner']) ")
						el-form-item(label="Brand Assignee" label-position="top" label-width="auto")
							el-select.w-100(filterable="" size="mini" v-model="filters.brand_assignee", placeholder="Brand Assignee", @change="fetchData")
								//el-option.text-bold(label='All', value='')
								el-option(v-for="o of brand_owners_options" :label="`${o.t} (${o.n})`" :value="o.v")


					el-col(v-if="permit(3) && permitColumn(['funnel_family_id','original_funnel_family_id'], false) && (filters.source_app === 'IFRAME' || filters.source_app === 'FLOW' || filters.source_app === 'GOOGLE_SHEETS')" :xs="12", :sm="6" :lg="3")
						el-form-item.v-desktop(label="Funnel Family" label-position="top" label-width="auto")
							el-select.w-100(:collapse-tags="true" filterable="",  multiple="" size="mini"  :disabled="filters.brand == ''"  v-model='filters.funnel_family', placeholder='By Funnel Family', @change="funnelFamilySelected")
								el-option.text-bold(label='All', value='-1' :disabled="filters.funnel_family.length > 0 && filters.funnel_family[0] !== 'all'")
								el-option(v-for="o of funnel_families_options" :label="o.t + (o.rn ? ` (${o.rn})` : '')" :value="o.v")
									template(slot="label")
										span {{o.rn + (o.rn ? ` (${o.rn})` : '')}}

						el-form-item.v-mob(label="Family" label-position="top" label-width="auto")
							el-select.w-100(:collapse-tags="true" filterable="",  multiple="" size="mini"  :disabled="filters.brand == ''"  v-model='filters.funnel_family', placeholder='By Funnel Family', @change="funnelFamilySelected")
								el-option.text-bold(label='All', value='-1' :disabled="filters.funnel_family.length > 0 && filters.funnel_family[0] !== 'all'")
								el-option(v-for="o of funnel_families_options" :label="o.t" :value="o.v")

					el-col(v-if="permit(3) && permitColumn(['funnel_id','funnel_name']) && (filters.source_app === 'IFRAME' || filters.source_app === 'FLOW')" :xs="12", :sm="6" :lg="3")
						el-form-item(label="Funnel" label-position="top" label-width="auto")
							el-select.w-100(:collapse-tags="true" filterable="",  multiple="" size="mini" :disabled="filters.brand == '' && filters.funnel_family == ''" v-model='filters.funnel', placeholder='By Funnel', @change="fetchData")
								el-option.text-bold(label='All', value='' :disabled="filters.funnel.length > 0 && filters.funnel[0] !== 'all'")
								el-option(v-for="o of funnels_options",
									:label="`${o.t + (o.nt ? ` (${o.nt})` : '')} ${o.n && 1==2 ? ' - (' + o.n +')' : ''}` + ((filters.funnel_family[0] === '-1') && (filters.brand.includes(o.b) || filters.brand[0] == '-1') ? ` - (${o.n}, ${o.it})` : '')",
									:value="o.v" v-if="(filters.funnel_family[0] === '-1') && (filters.brand.includes(o.b) || filters.brand[0] == '-1') || ((filters.brand.includes(o.b) || filters.brand[0] == '-1') && filters.funnel_family.includes(o.ff))" :disabled="filters.funnel.length === 1 && filters.funnel[0] === 'all'")

					el-col(v-if=" filters.source_app === 'GOOGLE_SHEETS' && permitColumn(['spreadsheet_id'])" :xs="12", :sm="6" :lg="3")
							el-form-item(label="Spreadsheet" label-position="top" label-width="auto")
									el-select.w-100(v-model="filters.spreadsheet" :collapse-tags="true" filterable="",  multiple="" size="mini" placeholder='Google Sheets', @change="fetchData" :disabled="filters.brand == '' && filters.funnel_family == ''" )
										el-option.text-bold(label='All', value='' :disabled="filters.spreadsheet.length > 0 && filters.spreadsheet[0] !== 'all'" )
										el-option(v-for="o of spreadsheet_options" :label="o.t" :value="o.v" v-if="(filters.funnel_family[0] === '-1') && (filters.brand.includes(o.b) || filters.brand[0] == '-1') || ((filters.brand.includes(o.b) || filters.brand[0] == '-1') && filters.funnel_family.includes(o.ff))"  :disabled="filters.spreadsheet.length === 1 && filters.spreadsheet[0] === 'all'")

					el-col(v-if=" filters.source_app === 'API' && permitColumn(['network_campaign','original_campaign_id'], false)" :xs="12", :sm="6" :lg="3")
						el-form-item(label="Campaign" label-position="top" label-width="auto")
									el-select.w-100(v-model="filters.campaign" :collapse-tags="true" filterable="",  multiple="" size="mini" placeholder='API Campaigns', @change="fetchData")
										el-option.text-bold(label='All', value='' :disabled="filters.campaign.length > 0 && filters.campaign[0] !== 'all'")
										el-option(v-for="o of campaigns_options" :label="o.t" :value="o.v")

					el-col(v-if=" filters.source_app === 'API'&& permitColumn(['network_campaign','original_campaign_id', 'affiliate_id','params_affiliate_id'], false)" :xs="12", :sm="12", :lg="3")
						el-form-item(label="Affiliate" label-position="top" label-width="auto")
							el-select.w-100(filterable="" multiple="" v-model="filters.affiliate_id"  size="mini" :collapse-tags="true" @change="fetchData")
								el-option(v-for="o of affiliates_options" :value="o.v" :label="`${o.t} (${o.n}) - ${o.i}`" )

					el-col(v-if="filters.source_app === 'API'&& permitColumn(['network_campaign','original_campaign_id', 'network_id'], false)" :xs="12" :sm="6" :lg="3")
						el-form-item(label="Network" label-position="top" label-width="auto" )
							el-select.w-100(filterable="" multiple="" v-model="filters.network_id"  size="mini" :collapse-tags="true" @change="fetchData")
								el-option(v-for="o of network_options" :value="o.v" :label="`${o.t} (${o.n}) - ${o.i}`" )

					el-col(:xs="12", :sm="6" :lg="3")
						el-form-item(label="Country" label-position="top" label-width="auto")
							el-select.w-100(:collapse-tags="true" filterable="",  multiple="" size="mini"  v-model='filters.country', placeholder='Country', @change="fetchData")
								el-option.text-bold(label='All', value='')
								el-option(v-for="o of country_options", :label="o.t", :value="o.v")
					el-col(:span="24" v-if="filters.source_app === ''")
						el-divider.v-mob.mt-0.mb-0
					el-col(:xs="12", :sm="12", :lg="6" :xl="3")
						el-form-item(label="FTD Date" label-position="top" label-width="auto")
							el-date-picker.w-100(v-model='filters.ftd_date_range', size="mini"  type='daterange', start-placeholder='Start date',  :picker-options="pickerOptions"
								end-placeholder='End date', :default-time="['00:00:00', '23:59:59']", value-format="yyyy-MM-dd", @change="fetchData")

					el-col(:xs="12", :sm="6", :lg="4" :xl="3")
						el-form-item(label="Failed Reason" label-position="top" label-width="auto")
							el-select.w-100(filterable="", multiple="" size="mini" v-model='filters.failed_reason', placeholder='FTD', @change="fetchData")
								el-option.text-bold(label='All', value='all')
								el-option(style="" v-for="o of failed_reason_options" :label="o.t.length > 150 ? `${o.t.substr(0, 150)}...` : o.t" :value="o.v" :key="o.t")

					el-col(:xs="12", :sm="6", :lg="4"  :xl="3" v-if="permit(2)")
						el-form-item(label="Statuses" label-position="top" label-width="auto")
							el-select.w-100(:collapse-tags="true" multiple="" filterable="" allow-create="" v-model='filters.original_status' placeholder="Select status" size="mini", @change="selectStatus")
								el-option.text-bold(label='All', value='all' :disabled="filters.original_status.length > 0 && !filters.original_status.includes('<') && !filters.original_status.includes('>')")
								el-option.text-bold(label='With Status', value='>' :disabled="filters.original_status.length > 0 && !filters.original_status.includes('<') && !filters.original_status.includes('all')")
								el-option.text-bold(label='No Status', value='<' :disabled="filters.original_status.length > 0 && !filters.original_status.includes('>') && !filters.original_status.includes('all')")
								el-option(v-for="o of status_options", :label="o.t", :value="o.v" :disabled="filters.original_status.includes('<') || filters.original_status.includes('>') || filters.original_status.includes('all')")

					// @todo - get it back after the spreadsheet->worksheet fix in the backend
					//el-col(v-if="filters.source_app !== '' && filters.source_app !== 'all'" :xs="12", :sm="12", :lg="3")
					//el-col(v-if="filters.source_app !== '' && filters.source_app !== 'all' && filters.source_app !== 'GOOGLE_SHEETS'" :xs="12", :sm="12", :lg="3")
					el-col( :xs="12", :sm="12", :lg="3")
						el-form-item(label="Languages" label-position="top" label-width="auto")
							el-select.w-100(:collapse-tags="true" multiple="" filterable="" allow-create="" v-model='filters.language' placeholder="Select languages" size="mini", @change="fetchData")
								el-option.text-bold(label='Select Language', value='')
								el-option(v-for="o of language_options", :label="o.t", :value="o.v")

					el-col(v-if="permitColumn(['traffic_source'])" :xs="12", :sm="12", :lg="3")
						el-form-item(label="Traffic Source" label-position="top" label-width="auto")
							el-select.w-100(:collapse-tags="true" multiple="" filterable="" allow-create="" v-model='filters.traffic_source' placeholder="Select sources" size="mini", @change="fetchData")
								el-option.text-bold(label='Select Source', value=''  :disabled="filters.traffic_source.length > 0 && !filters.traffic_source.includes('')")
								el-option.text-bold(label='- No Source -', value='-1' :disabled="filters.traffic_source.length > 0 && !filters.traffic_source.includes('-1')")
								el-option(v-for="o of traffic_source_options", :label="o.t + `- ${o.n}`", :value="o.v" :disabled="filters.traffic_source.includes('-1') || filters.traffic_source.includes('')")


					//el-col(:span="24" v-if="filters.source_app !== ''")
					el-col.v-lg-xl(:span="24" v-if="filters.source_app !== '1'")
						.w-100.line
					el-col(:xs="12" :sm="12" :md="6" :lg="5" :xl="3")

						el-tooltip()
							template(slot="content")
								.info-box()
									label
										b Changing this flag will change the auto refresher behaviour:
									ol
										li <b class="text-success">Auto</b> - The table will automatically refresh each time you change a filter or every minute, so that the leads will be shown in realtime.
										li <b class="text-danger">Manual</b> - Leave the list as is until the user clicks on the refresh button. Changing a filter won't trigger a refresh.
										li <b class="text-warning">On Filter</b> - Stop automatic refresher on timer but do refresh the list when a certain filter changes.
							el-form-item(label-position="top" label-width="auto")
								template(slot="label")

									span Auto Refresh Lead&nbsp;
										el-icon.chooseable(name="info")
								el-radio-group.w-100.text-left(size="mini" v-model="refreshLeadFlag" @change="changeRefreshFlag"  :fill="refreshLeadFlag === 1 ? '#409EFF' : refreshLeadFlag === -1 ? '#E6A23C' : 'red'")
									el-radio-button(:label="1") Auto
									el-radio-button(:label="-1") On Filter
									el-radio-button(:label="0") Manual



					el-col(:xs="12" :sm="12" :md="6" :lg="4" :xl="3")
						el-tooltip()
							template(slot="content")
								.info-box()
									label
										b You can lock or release the filters lock.
									ol
										li <b class="text-success">Lock</b> - If it is set to "lock" mode - allt he changes to the filters will be saved in the browser.
										li <b class="text-warning">Unlock</b> - If you set to "unlock" mode - nothing will be saved and the filters will be reset on a next page refresh or routing.
							el-form-item(label-position="top" label-width="auto")
								template(slot="label")

									span Filters Lock Mode&nbsp;
										el-icon.chooseable(name="info")
								el-radio-group.w-100.text-left(size="mini" v-model="filtersLockFlag" @change="changeLockFilterFlag"  :fill="filtersLockFlag == '1' ? '#409EFF' : '#E6A23C'")
									el-radio-button(label="1") Lock
									el-radio-button(label="-1") Unlock


					el-col(v-if="csmp" :xs="12" :sm="12" :md="6" :lg="4" :xl="3")
						el-tooltip()
							template(slot="content")
								.info-box()
									label
										b Include Mapped params
									p Toggling it on will open fetch the mapped params as well. Affects both the main table and the Excel Preadsheet generation.
									ol
										li <b class="text-success">Join</b> - Join the params, add the 
										li <b class="text-warning">Normal</b> - If y
									p.text-warning
										i * Note: This may increase the load time due to joins of different datasets
							el-form-item(label-position="top" label-width="auto")
								template(slot="label")
									span Join Mapped Params&nbsp;
										el-icon.chooseable(name="info")
								el-radio-group.w-100.text-left(size="mini" v-model="filters.join_mapped_params" @change="fetchData"  :fill="filters.join_mapped_params == '1' ? '#409EFF' : '#E6A23C'")
									el-radio-button(:label="1") Join
									el-radio-button(:label="0") Normal
					el-col(:xs="12" :sm="12" :md="4" :lg="3" :xl="3" v-if="cvd")
						el-form-item(label="Show Deleted Leads" label-position="top" label-width="auto")
							el-radio-group.w-100.text-left(size="mini" v-model="filters.deleted" @change="fetchData"  :fill="filters.deleted === 1 ? '#409EFF' : '#E6A23C'")
								el-radio-button(:label="1") Show
								el-radio-button(:label="0") Hide

					//el-col(:xs="12", :sm="12", :lg="3")
						el-form-item(label="Multi CID" label-position="top" label-width="auto")
							el-select.w-100(multiple=""	filterable="" allow-create="" placeholder="Choose tags for filtering" size="mini")

					//el-col(:xs="12", :sm="12", :lg="3")
						el-form-item(label="Multi Email" label-position="top" label-width="auto")
							el-select.w-100(multiple=""	filterable="" allow-create="" placeholder="Choose tags for filtering" size="mini")

					// @todo - check what the hell with the W/O Comments
					//el-col(:xs="6", :sm="6", :lg="3")
						el-form-item(label="W/O Comments"  multiple="" label-position="top" label-width="auto")
							el-select.w-100(size="mini", filterable="", v-model='filters.wo_comments', placeholder='W/O Comments', @change="fetchData")
								el-option.text-bold(label='All', value='0')
								el-option(label='With', value='1')
								el-option(label='Without', value='2')
			//el-divider
			hr.v-desktop
			//pre {{ multipleSelection }}

			.d-flex.justify-content-between.v-desktop( v-if="!filters.deleted")
				h5 Bulk Functions
				.justify-content-end.v-desktop
					el-button.small-btn-action(v-if="permit(1) && crl" v-b-tooltip.html.hover.top="", title="Edit lead's info in bulk" type="danger", size="small",  icon="el-icon-s-tools" @click="showDetailsUpdate('bulk')" :disabled="multipleSelection.length === 0") Edit Details
					el-button.small-btn-action(v-if="permit(3) && crl && (filters.source_app === 'IFRAME' || filters.source_app === 'FLOW')" v-b-tooltip.html.hover.top="", title="Add to reassign queue" type="warning", size="small",  icon="el-icon-refresh" @click="addToReassignQue" :disabled="multipleSelection.length === 0") Reassign Queue
					el-button.small-btn-action(v-if="permit(4) && cftd" v-b-tooltip.html.hover.top="", title="Toggle FTD" type="success", size="small",  icon="el-icon-info" @click="bulkFtd" :disabled="multipleSelection.length === 0") FTD
					el-button.small-btn-action(v-if="permit(1) && ccl && (filters.source_app === 'IFRAME' || filters.source_app === 'FLOW')" v-b-tooltip.html.hover.top="", title="Copy leads to another brand" type="info", size="small",  icon="el-icon-plus" @click="bulkCopy" :disabled="multipleSelection.length === 0") Copy
					el-button.small-btn-action(v-if="permit(2) && crl && (filters.source_app === 'IFRAME' || filters.source_app === 'FLOW')" v-b-tooltip.html.hover.top="", title="Reassign or retry register the lead" type="warning", size="small",  icon="el-icon-refresh" @click="bulkReassign" :disabled="multipleSelection.length === 0") Re-assign
					el-button.small-btn-action(v-if="permit(1)" v-b-tooltip.html.hover.top="", title="Remove Entity" type="danger", size="small",  icon="el-icon-remove" @click="bulkDelete" :disabled="multipleSelection.length === 0") Remove

			el-row.v-desktop(:gutter="12")
				el-col.v-mob(:span="8" v-if="permit(4) && cftd")
					el-button.small-btn-action.w-100(v-b-tooltip.html.hover.top="", title="Toggle FTD" type="success", size="small",  icon="el-icon-info" @click="bulkFtd" :disabled="multipleSelection.length === 0") FTD
				el-col.v-mob(:span="8" v-if="permit(3) && crl && (filters.source_app === 'IFRAME' || filters.source_app === 'FLOW')")
					el-button.small-btn-action.w-100(v-b-tooltip.html.hover.top="", title="Add to reassign que" type="warning", size="small",  icon="el-icon-refresh" @click="bulkReassign" :disabled="multipleSelection.length === 0") Reassign Queue
				el-col.v-mob(:span="8" v-if="permit(1) && ccl && (filters.source_app === 'IFRAME' || filters.source_app === 'FLOW')")
					el-button.small-btn-action.w-100(v-b-tooltip.html.hover.top="", title="Copy the lead to another brand" type="info", size="small",  icon="el-icon-refresh" @click="bulkCopy" :disabled="multipleSelection.length === 0") Copy
				el-col.v-mob(:span="8" v-if="permit(1) && crl && (filters.source_app === 'IFRAME' || filters.source_app === 'FLOW')")
					el-button.small-btn-action.w-100(v-b-tooltip.html.hover.top="", title="Reassign or retry register the lead" type="warning", size="small",  icon="el-icon-refresh" @click="bulkReassign" :disabled="multipleSelection.length === 0") Re-assign
				el-col.v-mob(:span="8" v-if="permit(1)")
					el-button.small-btn-action.w-100(v-b-tooltip.html.hover.top="", title="Remove Entity" type="danger", size="small",  icon="el-icon-remove" @click="bulkDelete" :disabled="multipleSelection.length === 0") Remove

			el-divider.v-mob

			.v-mob.d-flex.justify-content-between
					label.label-text-gray-mob <b>Total:</b> {{count | numeral(0,0)}}
					label.label-text-gray-mob(v-if="$store.state.ROLE_ID < '4'") <b>FTDs:</b> {{ftds | numeral(0,0)}}
					label.label-text-gray-mob(v-if="$store.state.ROLE_ID < '4'") <b>Conv. Rate:</b> {{conv_rate}}%

			.d-flex.justify-content-between
				b-pagination.mt-3.v-desktop(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")
				.mt-4.v-desktop
					label.label-text-gray <b>Total:</b> {{count | numeral(0,0)}},&nbsp;&nbsp;&nbsp;
					label.label-text-gray(v-if="$store.state.ROLE_ID < '4'") <b>FTDs:</b> {{ftds | numeral(0,0)}},&nbsp;&nbsp;&nbsp;
					label.label-text-gray(v-if="$store.state.ROLE_ID < '4'") <b>Conv. Rate:</b> {{conv_rate}}%

			//pre {{network_hash}}
			cust-horiz-scroll
				el-table.funnels-tbl.v-desktop.mt-3(id="data-list" ref="multipleTable" v-loading='busy', stripe="", @selection-change="handleSelectionChange" :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='' :row-class-name="tableRowClassName")

					el-table-column(type="selection" :width="55" v-if="permit(2) && !filters.deleted" )

					el-table-column(align="center" label="App" :width="50" v-if="permit(3)" )
						template(slot-scope="scope" )

							font-awesome-icon.p-0.text-danger( v-if="filters.deleted" v-b-tooltip.html.hover-right="", :title="'Deleted'", size="lg", :icon="['fa', 'times-circle']")
							font-awesome-icon.p-0.text-danger(v-else-if="scope.row.busy" v-b-tooltip.html.hover-right="", :title="'Locked Entry'", size="lg", :icon="['fa', 'lock']")
							font-awesome-icon.p-0(v-else="" v-b-tooltip.html.hover-right="", :title="'Type: ' + scope.row.source_app", size="lg", :icon="[scope.row.source_app === 'IFRAME' ? 'fab' : scope.row.source_app === 'API' ? 'fas' : scope.row.source_app === 'FLOW' ? 'fa' : 'far', scope.row.source_app === 'IFRAME' ? 'internet-explorer' : scope.row.source_app === 'FLOW' ? 'angle-double-right' : scope.row.source_app === 'API' ? 'code' : 'file-excel']")
					//el-table-column(v-if="selectedColumns['Show/Hide']" align="center" label='Show/Hide' :width="150")
						template(slot-scope="scope")
							el-radio-group(v-if="scope.row.source_app === 'API'" size="mini" :fill="scope.row.show_to_affiliate ? '#409EFF' : 'red'" v-model="scope.row.show_to_affiliate" @change="handleShowChange(scope.$index, scope.row.uuid, scope.row.show_to_affiliate)")
								el-radio-button(:label="true") Show
								el-radio-button(:label="false") Hide


					// *********** TODO - find a way how to make "fixed" column work when being wrapped in a horizontal scroll component
					el-table-column(align='center', label='#' :width='95' )
						template(slot-scope='scope')
							| {{ permitColumn(['id']) ? scope.row.id : scope.row.short_uuid }}
							| &nbsp;

							small.d-block.text-success.chooseable(v-if="scope.row.original_lead_id", v-b-tooltip.html.hover.top="", title="Click to show original lead data" @click="loadReferenceLead(scope.row.original_lead_id)") ({{scope.row.original_lead_id}})
					el-table-column( v-if="selectedColumns['Full Name']" align='center', label='Full Name')
						template(slot-scope='scope')
							span.chooseable() {{ scope.row.first_name + ' ' + scope.row.last_name }}
					el-table-column( v-if="selectedColumns['CID']" align='center', label="CID")
						template(slot-scope="scope")
							span.chooseable() {{ scope.row.cid || '-' }}

					el-table-column( v-if="selectedColumns['UUID']" align='center', label="UUID" :width="200")
						template(slot-scope="scope")
							span.chooseable() {{ scope.row.short_uuid || '-' }}

					el-table-column( v-if="selectedColumns['E-Mail']" align='center', label='E-Mail' :width="290")
						template(slot-scope='scope')
							small {{ scope.row.email || '-' }}
					el-table-column( v-if="selectedColumns['Phone']"  align='center', label="Phone")
						template(slot-scope="scope")
							span {{ scope.row.phone || '-' }}
					el-table-column( v-if="selectedColumns['Country']"  align='center', label="Country", :width="150")
						template(slot-scope="scope")
							span {{ scope.row.country ? countries_hash_basic[scope.row.country] : '-' }}
							img.ml-2(style="width:20px" :src="require('../assets/img/flags/' + scope.row.country.toLowerCase() + '.svg')")
					el-table-column( v-if="selectedColumns['Resolved Country']"  align='center', label="Resolved Country", :width="150")
						template(slot-scope="scope")
							el-tooltip(v-if="scope.row.resolved_country_iso")
								template(slot="content")
									label.d-block Resolved by Maxmind GeoLite2 / DB-IP / IP2Location
									ul
										li <b>IP: </b> {{scope.row.ip_addr}}
								div
									span {{ scope.row.resolved_country_iso ? countries_hash_basic[scope.row.resolved_country_iso] : '-' }}
									img.ml-2(v-if="scope.row.resolved_country_iso" style="width:20px" :src="require('../assets/img/flags/' + scope.row.resolved_country_iso.toLowerCase() + '.svg')")
							span(v-else="") -
					el-table-column(v-if="selectedColumns['Resolved Region']" align='center', label="Resolved Region", :width="150")
						template(slot-scope="scope")
							el-tooltip( v-if="scope.row.resolved_region_name")
								template(slot="content")
									label.d-block Resolved by Maxmind GeoLite2 / DB-IP / IP2Location
									ul
										li <b>IP: </b> {{scope.row.ip_addr}}
										li <b>Country: </b> {{ countries_hash_basic[scope.row.resolved_country_iso] || 'Unknown'  }}
								span {{ scope.row.resolved_region_name  }}
							span(v-else="") -
					el-table-column(v-if="selectedColumns['Resolved City']" align='center', label="Resolved City", :width="150")
						template(slot-scope="scope")
							el-tooltip( v-if="scope.row.resolved_city_name")
								template(slot="content")
									label.d-block Resolved by Maxmind GeoLite2 / DB-IP / IP2Location
									ul
										li <b>IP: </b> {{scope.row.ip_addr}}
										li <b>Country: </b> {{ countries_hash_basic[scope.row.resolved_country_iso] || 'Unknown'  }}
								span {{ scope.row.resolved_city_name  }}
							span(v-else="") -
					el-table-column( v-if="selectedColumns['Visit ID']" align='center', label='Visit#' :width="110")
						template(slot-scope='scope')
							span.chooseable(v-b-tooltip.html.hover.top="", title="Go to 'visits' modal (3rd button on the top corner) to view the visit's data") {{ scope.row.visit_id || '-' }}
					el-table-column( v-if="selectedColumns['Traffic Source']"  align='center', label="Traffic Source" :width="editModeCount === 0 ? 140 : 220")
						template(slot-scope="scope")
							div(v-if="cets")
								el-tooltip(v-if="!scope.row[`edit_mode_traffic_source`]")
									template(slot="content")
										div(v-if="scope.row.real_source && scope.row.real_source.length > 18")
											span() {{scope.row.real_source}}
											| &nbsp;-&nbsp;
											span() Click to change
										span(v-else="") Click to change
									el-tag.clickable(v-bind:class="{'text-danger':!scope.row.real_source }"  :type="!scope.row.real_source ? 'danger' : 'warning'"  @click="toggleInlineEdit(scope.$index, scope.row.uuid, 'traffic_source')") {{ (scope.row.real_source ? (scope.row.real_source) : '*Other') | ellipsis18chars }}
								div(v-if="scope.row[`edit_mode_traffic_source`]" class="el-input el-input--mini el-input-group el-input-group--append el-input-group--prepend")
									.el-input-group__prepend
										el-button( size="small"  icon="el-icon-delete" v-b-tooltip.html.hover.top="", title="Click to cancel edit mode" @click="saveInlineEdit(null, scope.$index, scope.row.uuid, 'traffic_source')")

									el-select.w-100(v-if="scope.row[`edit_mode_traffic_source`]" v-model="scope.row.real_source_uuid" size="mini" placeholder="Traffic Source" filterable="")
										el-option(v-for="o of traffic_source_options", :label="o.t + `- ${o.n}`", :value="o.v")

									.el-input-group__append
										el-button(size="small"  icon="el-icon-s-promotion"  v-b-tooltip.html.hover.top="", title="Click to save the value" @click="trafficSourceInlineUpdate(scope.row.real_source_uuid, scope.$index, scope.row.uuid, 'traffic_source')")
										//el-button(size="small"  icon="el-icon-s-promotion" v-b-tooltip.html.hover.top="", title="Click to save the value" @click="saveInlineEdit(scope.row.real_source, scope.$index, scope.row.uuid, 'traffic_source')")
							span(v-else="")
								el-tag(v-bind:class="{'text-danger':!scope.row.real_source }"  :type="!scope.row.real_source ? 'danger' : 'warning'") {{ scope.row.real_source || '*Other' }}
					el-table-column( v-if="selectedColumns['Timestamp']"  align='center', label="Timestamp" :width="150")
						template(slot-scope="scope")
							span {{ scope.row.date_created || '-' }}

					// @this
					//el-table-column(v-if="tableExtended"  align='center', label="FTD Date"  :width="150")
					el-table-column( v-if="selectedColumns['FTD Date']"   align='center', label="FTD Date"  :width="150")
						template(slot-scope="scope")
							span {{ scope.row.ftd_date || '-' }}
					el-table-column( v-if="selectedColumns['FTD']" align="center" label="FTD" :width="60")
						template(slot-scope="scope" )
							el-tooltip(:content="scope.row.ftd_date || 'No FTD'")
								font-awesome-icon.icon.alt.text-danger(v-if="!scope.row.ftd", :icon="['fas', 'times']")
								font-awesome-icon.icon.alt.text-success(v-else="", :icon="['fas', 'check']")
					el-table-column( v-if="selectedColumns['Language']"  align='center', label="Language")
						template(slot-scope="scope")
							span {{ language_hash[scope.row.original_language] || '-' }}
							//span {{ scope.row.network_campaign && scope.row.network_campaign.language_full ? scope.row.network_campaign.language_full : scope.row.funnel && scope.row.funnel.language_full ? scope.row.funnel.language_full : scope.row.spreadsheet && scope.row.spreadsheet.language_full ? scope.row.spreadsheet.language_full : '-' }}
					el-table-column( v-if="selectedColumns['Brand']"  align='center', label="Brand")
						template(slot-scope="scope")
							span {{ scope.row.brand ? scope.row.brand.name : '-' }}&nbsp;
							el-tooltip(:content="'Original Brand: ' + (scope.row.original_brand ? scope.row.original_brand.name : '')" v-if="scope.row.original_brand_id")
								i.el-icon-question.chooseable.no-deco()
					el-table-column( v-if="selectedColumns['Brand Global']"  align='center', label="Brand - Global")
						template(slot-scope="scope")
							span {{ scope.row.brand ? scope.row.brand.global_name : '-' }}&nbsp;
					el-table-column(v-if="selectedColumns['Affiliate (Param)'] && cspa" align='center' label="Affiliate (Param)"  :width="130")
						template(slot-scope="scope")
							el-tooltip(v-if="scope.row.params_affiliate_id && scope.row.params_affiliate_entry")
								template(slot="content")
									span.d-block Name: {{scope.row.params_affiliate_entry.first_name + ' ' + scope.row.params_affiliate_entry.last_name}}
									span.d-block E-Mail:&nbsp;
										a.text-warning(:href="scope.row.params_affiliate_entry.email") {{scope.row.params_affiliate_entry.email}}
								span.chooseable() {{ scope.row.params_affiliate_entry.first_name + ' ' + scope.row.params_affiliate_entry.last_name }}
							span.text-danger(v-else="")
								i No Affiliate
					el-table-column(v-if="selectedColumns['Brand Owner']" align='center' label="Brand Assignee"  :width="130")
						template(slot-scope="scope")
							el-tooltip(v-if="scope.row.brand && scope.row.brand.user_hash")
								template(slot="content")
									span.d-block Name: {{scope.row.brand.user_hash.first_name + ' ' + scope.row.brand.user_hash.last_name}}
									span.d-block E-Mail:&nbsp;
										a.text-warning(:href="scope.row.brand.user_hash.email") {{scope.row.brand.user_hash.email}}
								span.chooseable() {{ scope.row.brand.user_hash.first_name + ' ' + scope.row.brand.user_hash.last_name }}
							span.text-danger(v-else="")
								i Unknown
					el-table-column( v-if="selectedColumns['Funnel']  && permit(3)"  align='center', label="Funnel" )
						template(slot-scope="scope")
							el-tooltip()
								template(slot="content")
									label.d-block <b>Brand:</b> {{scope.row.brand.name}}
									label.d-block <b>Integration:</b> {{scope.row.original_integration && scope.row.original_integration.type  || '-'}}
									label.d-block <b>Funnel Family:</b> {{scope.row.original_funnel_family_id && scope.row.original_funnel_family_object ? scope.row.original_funnel_family_object.name : '-'}} ({{scope.row.original_funnel_family_id && scope.row.original_funnel_family_object ? scope.row.original_funnel_family_object.real_funnel_name : '-'}})
								span {{ scope.row.funnel ? scope.row.funnel.name : '-' }}
									small.text-success.ml-1 {{ scope.row.funnel ?  (scope.row.funnel.note ? ` (${scope.row.funnel.note})` : '') : '-' }}&nbsp;
							el-tooltip(:content="'Original Funnel: ' + (scope.row.original_funnel ? scope.row.original_funnel.name : '')" v-if="scope.row.original_brand_id")
								i.el-icon-question.chooseable.no-deco()
					el-table-column( v-if="selectedColumns['Funnel Family']"  align='center', label="F. Family" :width="120")
						template(slot-scope="scope")
							span(v-if="scope.row.funnel_family_name && scope.row.real_funnel_name") {{ scope.row.funnel_family_name }}&nbsp;
								small.text-success ({{ scope.row.real_funnel_name }})
							span(v-else-if="scope.row.funnel_family_name && !scope.row.real_funnel_name") {{ scope.row.funnel_family_name }}
							span.text-danger(v-else="")
								i Unclassified
					el-table-column( v-if="selectedColumns['Real Funnel Name']"  align='center', label="Real Funnel   " :width="120")
						template(slot-scope="scope")
							span(v-if="scope.row.real_funnel_name !== 'Unclassified' && scope.row.real_funnel_name") {{ scope.row.real_funnel_name }}
							span.text-danger(v-else="")
								i Unclassified
					el-table-column( v-if="selectedColumns['Original Funnel Family']"  align='center', label="Original F. Family" :width="120")
						template(slot-scope="scope")

							span(v-if="scope.row.original_funnel_family_id && scope.row.original_funnel_family_object") {{ scope.row.original_funnel_family_object.name }}&nbsp;
								small.text-success ({{ scope.row.original_funnel_family_object.real_funnel_name }})
							span.text-danger(v-else="")
								i Unclassified

					// @this
					//el-table-column(v-if="tableExtended" align='center', label="Spreadsheet")
					el-table-column( v-if="selectedColumns['Spreadsheet']"  align='center', label="Spreadsheet")
						template(slot-scope="scope")
							el-tooltip()
								template(slot="content")
									label Click on the title to open the spreadsheet in a new tab
									span.d-block <b>Title</b>: {{scope.row.spreadsheet_id ? scope.row.spreadsheet.title : '-' }}
									span.d-block <b>ID</b>: {{scope.row.spreadsheet_id ? scope.row.spreadsheet.hash : '-' }}

								span.chooseable( @click="openLink(scope.row.spreadsheet_id ? link + scope.row.spreadsheet.hash + '/edit#gid=0' : '')") {{scope.row.spreadsheet_id ? scope.row.spreadsheet.title : '-' }}

					el-table-column( v-if="selectedColumns['Affiliate']"	align='center', label="Affiliate")
						template(slot-scope="scope")
							el-tooltip(v-if="scope.row.affiliate")
								template(slot="content" )
									span.d-block Name: {{scope.row.affiliate ? (scope.row.affiliate.first_name + scope.row.affiliate.last_name) : '-' }}
									span.d-block ID: {{scope.row.affiliate ? scope.row.affiliate.id : '-' }}
									span.d-block E-Mail: {{scope.row.network_campaign ? scope.row.affiliate.email : '-' }}
								span.chooseable {{scope.row.affiliate ? scope.row.affiliate.email : 'Unknown'}}
							span.chooseable(v-else="") {{scope.row.affiliate ? scope.row.affiliate.email : 'Unknown'}}

					el-table-column( v-if="selectedColumns['Campaign']"	align='center', label="Campaign" :width="300")
						template(slot-scope="scope")
							el-tooltip()
								template(slot="content")
										span.d-block Name: {{scope.row.network_campaign && scope.row.network_campaign.campaign ? scope.row.network_campaign.campaign.name : '-' }}
										span.d-block UUID: {{scope.row.network_campaign && scope.row.network_campaign.campaign ? scope.row.network_campaign.campaign.uuid : '-' }}
										span.d-block Network Campaign: {{scope.row.network_campaign ? scope.row.network_campaign.uuid : '-' }}
								span.chooseable {{scope.row.network_campaign && scope.row.network_campaign.campaign ? scope.row.network_campaign.campaign.name  : '-' }}


						// @this
					//el-table-column(v-if="tableExtended"  align='center', label="Integration" :width="120" )
					el-table-column( v-if="selectedColumns['Integration']"   align='center', label="Integration" :width="120" )
						template(slot-scope="scope")
							span(v-if="scope.row.original_integration") {{ scope.row.original_integration.type + ' - ' + (scope.row.original_integration.note ? `${scope.row.original_integration.note}` : '')}}
							span(v-else="") -
							//span(v-if="scope.row.source_app === 'GOOGLE_SHEETS'") {{ scope.row.spreadsheet_id ? scope.row.spreadsheet.integration.type + ' - ' + (scope.row.spreadsheet.integration.note ? `${scope.row.spreadsheet.integration.note}` : '') : '-' }}
							//span(v-if="scope.row.source_app === 'API'")           {{ scope.row.network_campaign && scope.row.network_campaign.integration ? scope.row.network_campaign.integration.type + ' - ' + (scope.row.network_campaign.integration.note ? `${scope.row.network_campaign.integration.note}` : '') : '-' }}
							//span(v-else)                                          {{ scope.row.funnel_id ? scope.row.funnel.integration.type + ' - ' + (scope.row.funnel.integration.note ? `${scope.row.funnel.integration.note}` : '') : '-' }}

					el-table-column( v-if="selectedColumns['Param X']" align='center',  label="Param X" :width="240")
						template(slot-scope="scope")
							div( v-if="permit(2)")
								el-tooltip(v-if="!scope.row[`edit_mode_param_x`]" content="Click to edit")
									el-tag.clickable( :type="(scope.row.param_x || 'No Value Set...') | t_status_param" @click="toggleInlineEdit(scope.$index, scope.row.uuid, 'param_x')") {{ scope.row.param_x || 'No Value Set...' }}
								el-tooltip(v-if="!scope.row[`edit_mode_param_x`] && scope.row.param_x" content="Clear Param")
									el-tag.clickable.param-delete-class( type="warning" @click="crearParamXYValue(scope.$index, scope.row.uuid, 'param_x')")
										i.el-icon-close.chooseable()
								div(v-if="scope.row[`edit_mode_param_x`]" class="el-input el-input--mini el-input-group el-input-group--append el-input-group--prepend")
									.el-input-group__prepend
										el-button( size="small"  icon="el-icon-delete" v-b-tooltip.html.hover.top="", title="Click to cancel edit mode" @click="saveInlineEdit(null, scope.$index, scope.row.uuid, 'param_x')")
									el-date-picker.w-100(type="datetime" v-model="scope.row.param_x" size="mini" placeholder="FTD Date")
									.el-input-group__append
										el-button(size="small"  icon="el-icon-s-promotion" v-b-tooltip.html.hover.top="", title="Click to save the value" @click="saveInlineEdit(scope.row.param_x, scope.$index, scope.row.uuid, 'param_x')")
							div(v-if="!permit(2)")
								el-tag( :type="(scope.row.param_x || 'No Value Set...') | t_status_param") {{ scope.row.param_x || 'No Value Set...' }}
					el-table-column( v-if="selectedColumns['Param Y']" align='center',  label="Param Y" :width="180")
						template(slot-scope="scope")
							div( v-if="permit(2)")
								el-tooltip(v-if="!scope.row[`edit_mode_param_y`]" content="Click to edit")
									el-tag.clickable( :type="(scope.row.param_y || 'No Value Set...') | t_status_param" @click="toggleInlineEdit(scope.$index, scope.row.uuid, 'param_y')") {{ scope.row.param_y || 'No Value Set...' }}
								el-tooltip(v-if="!scope.row[`edit_mode_param_y`] && scope.row.param_y" content="Clear Param")
									el-tag.clickable.param-delete-class( type="warning" @click="crearParamXYValue(scope.$index, scope.row.uuid, 'param_y')")
										i.el-icon-close.chooseable()
								el-input(v-if="scope.row[`edit_mode_param_y`]" v-model="scope.row.param_y" size="mini" placeholder="Status Sting")
									el-button.small-icon.p-0(size="small" slot="prepend" type="danger" icon="el-icon-delete" v-b-tooltip.html.hover.top="", title="Click to cancel edit mode" @click="saveInlineEdit(null, scope.$index, scope.row.uuid, 'param_y')")
									el-button.small-icon.p-0(size="small" slot="append" type="primary" icon="el-icon-s-promotion" v-b-tooltip.html.hover.top="", title="Click to save the value" @click="saveInlineEdit(scope.row.param_y, scope.$index, scope.row.uuid, 'param_y')")
							div(v-if="!permit(2)")
								el-tag( :type="(scope.row.param_y || 'No Value Set...') | t_status_param") {{ scope.row.param_y || 'No Value Set...' }}
					// @this
					//el-table-column(v-if="tableExtended" align='center', label="IPv4", :width="145")
					el-table-column( v-if="selectedColumns['IPv4']"  align='center', label="IPv4", :width="145")
						template(slot-scope="scope")
							span.chooseable(@click="geoIpGuess(scope.row.ip_addr)") {{ scope.row.ip_addr || '-' }}
					el-table-column( v-if="selectedColumns['Comments']" align='center', label="Comments" :width="100")
						template(slot-scope="scope")
							span.chooseable(v-if="scope.row.comment && scope.row.comment.length > 0" @click="showComments(scope.row)") Show
							span(v-else="") -

					// @this
					//el-table-column(v-if="tableExtended" align='center', label="JC")

					el-table-column( v-if="selectedColumns['Product']"  align='center', label="Product")
						template(slot-scope="scope")
							el-tooltip(v-if="scope.row.product_name")
								template(slot="content")
									span.d-block <b>Product SKU:</b> {{scope.row.product_sku || '-'}}
									span.d-block <b>Product Name:</b> {{scope.row.product_name}}
									span.d-block.text-warning.clickable(@click="openLink(scope.row.product_url)") <b>Product URL:</b> {{scope.row.product_url}}
								span.chooseable() {{ scope.row.product_name }}
							span.text-info(v-else="")
								i None


					el-table-column( v-if="selectedColumns['Network']"  align='center', label="Network")
						template(slot-scope="scope")

							el-tooltip(v-if="scope.row.network_id && network_hash[`${scope.row.network_id}`]")
								template(slot="content")
									label.d-block <b>Network Details</b>
									span.d-block <b>NTID:</b> {{network_hash[`${scope.row.network_id}`].i}}
									span.d-block <b>Name:</b> {{network_hash[`${scope.row.network_id}`].t}} ({{network_hash[`${scope.row.network_id}`].n}})
									span.d-block <b>Comments:</b> {{network_hash[`${scope.row.network_id}`].c}}
								span.chooseable() {{network_hash[`${scope.row.network_id}`].t}} ({{network_hash[`${scope.row.network_id}`].n}})
							span.text-info(v-else="")
								i None

					el-table-column( v-if="selectedColumns['JC']"  align='center', label="JC")
						template(slot-scope="scope")
							span() {{ scope.row.jc_grp_id || '-' }}

					el-table-column( v-if="selectedColumns['ClickID']"  align='center', label="ClickID")
						template(slot-scope="scope")
							span() {{ scope.row.params.clickid || scope.row.params.click_id || scope.row.params.clickId || scope.row.params.clickID || scope.row.params.gid || '-' }}

					el-table-column( v-if="selectedColumns['Status - Broker']"  align='center', label="Status - Broker")
						template(slot-scope="scope")
							span() {{ scope.row.original_status || '-' }}

					el-table-column( v-if="selectedColumns['Status - Mapped']"  align='center', label="Status - Mapped")
						template(slot-scope="scope")
							span() {{ scope.row.mapped_status || '-' }}

					el-table-column( v-if="selectedColumns['Conv. Status']"  align='center', label="Conv. Status")
						template(slot-scope="scope")
							span() {{ scope.row.conv_status || '-' }}
					el-table-column( v-if="selectedColumns['Failed Reason']"  align='center', label="Failed Reason" :width="240")
						template(slot-scope="scope")
							el-tooltip(v-if="scope.row.failed_reason && scope.row.failed_reason.length > 128")
								template(slot="content")
									div(v-bind:style="{width: '200px'}")
										p {{scope.row.failed_reason}}
								span() {{ scope.row.failed_reason  ? `${scope.row.failed_reason.substr(0,128)}...` : '-' }}
							span(v-else="") {{ scope.row.failed_reason ? scope.row.failed_reason :  '-' }}

					// @this
					//el-table-column(v-if="tableExtended && permit(1)" align='center', label="Params")
					el-table-column( v-if="selectedColumns['Params'] && permit(2)"  align='center', label="Params")
						template(slot-scope="scope")
							span.chooseable.long-text(@click="showParams(scope.row.params)") {{ !scope.row.params || Object.keys(scope.row.params).length === 0 ? '-' : 'Click here'  }}
					el-table-column( v-if="filters.join_mapped_params && selectedColumns['Mapped Params'] && permit(2)"  align='center', label="Mapped Params" :width="120")
						template(slot-scope="scope")
							span.chooseable.long-text(@click="showParams(scope.row.customer_params_summary[0], true)" v-if="scope.row.customer_params_summary && scope.row.customer_params_summary[0].lead_id") Click here
							span(v-else="") -
					el-table-column( v-if="selectedColumns['Actions']" align="center" label="Actions" :width="ced ? 205 : 165" )
						template(slot-scope="scope")
							.d-flex.justify-content-center
								el-button.small-btn-action.search-action-btn(v-if="ced" v-b-tooltip.html.hover-top="", title="Edit Lead's Details", type="danger", icon="el-icon-s-tools", size="mini", @click="showDetailsUpdate('single',scope.row)")
								el-button.small-btn-action.search-action-btn(v-if="ccm" v-b-tooltip.html.hover-top="", title="Show Meta", type="success", icon="el-icon-message-solid", size="mini", @click="showMetaData(scope.row.id)")
								el-button.small-btn-action.search-action-btn(v-if="ccsc" v-b-tooltip.html.hover-top="", title="Comments", type="info", icon="el-icon-edit", size="mini", @click="showComments(scope.row)")
								el-button.small-btn-action.search-action-btn(v-if="ccl && !filters.deleted && permit(1) && scope.row.cid && scope.row.phone && (scope.row.source_app === 'IFRAME' || scope.row.source_app === 'FLOW')" v-b-tooltip.html.hover.top="", title="Copy customer to another brand", type="primary", size="small",  icon="el-icon-plus", @click="toggleCopyModal(scope.row)")
								//el-button.small-btn-action.search-action-btn(v-if="ccl && !filters.deleted && permit(1) && scope.row.cid && scope.row.phone && scope.row.source_app === 'API'" v-b-tooltip.html.hover.top="", title="Copy customer to another brand (API)", type="primary", size="small",  icon="el-icon-plus", @click="toggleCopyModal(scope.row)")				// TODO
								el-button.small-btn-action.search-action-btn(v-if="crl && !filters.deleted && permit(2) && !scope.row.cid && scope.row.phone && (scope.row.source_app === 'IFRAME' || scope.row.source_app === 'FLOW') " v-b-tooltip.html.hover.top="", title="Retry Register (IFRAME/FLOW)", type="", size="warning",  icon="el-icon-refresh", @click="toggleReregisterModal(scope.row)")
								el-button.small-btn-action.search-action-btn(v-if="crl && !filters.deleted && permit(2) && !scope.row.cid && scope.row.phone && scope.row.source_app === 'API'" v-b-tooltip.html.hover.top="", title="Retry Register (API)", type="", size="warning",  icon="el-icon-refresh", @click="retryRegisterApi(scope.row)")
								el-button.small-btn-action.search-action-btn(v-if="!filters.deleted && permit(1)" v-b-tooltip.html.hover.top="", title="Remove Entity", type="danger", size="small",  icon="el-icon-remove", @click="removeEntity(scope.row.id)")
								el-button.small-btn-action.search-action-btn(v-if="!filters.deleted && !scope.row.ftd && scope.row.phone && cftd" v-b-tooltip.html.hover-top="", title="Toggle FTD", type="", icon="el-icon-message-solid", size="mini", @click="toggleFtd(scope.row.uuid)")

			.d-flex.justify-content-between.v-mob
				b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")
				el-row.mt-4
					el-col(:span="24")
						label.label-text-gray
							b Total:&nbsp;
							| {{ count | numeral('0,0') }}

				el-row
					el-col(:span="24")
						el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
							el-option(label="10 Per Page", :value="10")
							el-option(label="20 Per Page", :value="20")
							el-option(label="50 Per Page", :value="50")
							el-option(label="100 Per Page", :value="100")
							el-option(label="200 Per Page", :value="200")
			.v-mob.clickable(v-for="l of list", @click="toggleModal(l)")
				el-card.lead-card.mb-2(shadow="hover")
					template(slot="header")
						.d-flex.justify-content-between
							label
								b Lead {{'#' + l.id}}
							font-awesome-icon.p-0(v-b-tooltip.html.hover-right="", :title="'Type: ' + l.source_app", size="lg", :icon="[l.source_app === 'IFRAME' ? 'fab' : 'far', l.source_app === 'IFRAME' ? 'internet-explorer' : 'file-excel']")
					//template(slot="body")
					el-form.text-left()
						el-row(:gutter="12")
							el-col(:sm="24")
								el-form-item(label="E-Mail:")
									label {{ l.email }}
							el-col(:sm="24")
								el-form-item(label="Brand:")
									label {{ l.brand ? l.brand.name : '-' }}
							div.w-100.text-right
								small.text-danger
									i *Click on the card to view the lead's details
			.d-flex.justify-content-between
				b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")
				el-row.mt-4
					el-col(:span="24")
						.v-desktop.d-flex.justify-content-between
							label.label-text-gray <b>Total:</b> {{count | numeral(0,0)}},&nbsp;&nbsp;&nbsp;
							label.label-text-gray(v-if="$store.state.ROLE_ID < '4'") <b>FTDs:</b> {{ftds | numeral(0,0)}},&nbsp;&nbsp;&nbsp;
							label.label-text-gray(v-if="$store.state.ROLE_ID < '4'") <b>Conv. Rate:</b> {{conv_rate}}%
						//label.label-text-gray
							b Total:&nbsp;
							| {{count | numeral('0,0')}}

				el-row
					el-col(:span="24")
						el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
							el-option(label="10 Per Page", :value="10")
							el-option(label="20 Per Page", :value="20")
							el-option(label="50 Per Page", :value="50")
							el-option(label="100 Per Page", :value="100")
							el-option(label="200 Per Page", :value="200")
		el-dialog(title='Meta Data', :visible.sync='metaDialogShown', width='50%', center='')
			span
				b Meta Data
			el-form.mt-3
				el-row(:gutter='10')
					el-col(v-if="currentMeta.length===0" :span="24")
						h5.text-center Unfortunately there is no meta data available for this lead
					el-col(v-for='r of currentMeta', :span="24")
						el-form-item(:label='r.key', label-position='left', label-width='8rem')
							el-input(type='textarea', :value='r.value', read-only='', rows="5")

			span.dialog-footer(slot='footer')
				el-button(type='primary', @click='metaDialogShown = false') Ok

		el-dialog(title='Tracking Params', :visible.sync='paramsDialogShown', width='50%', center='')
			span
				b Tracking Params
			el-form.mt-3
				el-row(:gutter='10')
					el-empty.p-0(description="No Records..." :image-size="50" v-if="currentParams.length === 0")
					el-col(v-for='r of currentParams', :span="24")
						el-form-item(:label='r.t', label-position='left', label-width='5rem')
							el-input(type='textarea', v-model='r.v', read-only='')
			span.dialog-footer(slot='footer')
				el-button(type='primary', @click='paramsDialogShown = false') Ok

		el-dialog.override-width.search-dialog(width='60%', title="Export Excel Prompt" v-if="showExcelExportModal" :visible.sync='showExcelExportModal' @close="showExcelExportModal = false")
			el-row.break-word-text(:gutter="12")
				el-col.text-left(:span="24")
					h5 Instructions
					p You must choose whether to export a spreadsheet with all the available columns or select the necessary ones. Default - export all.

					p Selected columns:&nbsp;
						span.text-success(v-if="!spreadsheet_export.export_all_flag") {{ spreadsheet_export.columns.join(', ') }}
						span.text-success(v-else="") {{ spreadsheet_columns_list.join(', ') }}
					div(v-if="")
						p.text-danger(v-if="filters.join_mapped_params == 1")
							i <b>* Note: </b> The export includes the mapped params if there are - keep in mind that this may drastically add more export time. To turn it <b>off</b> - go back to the main table and toggle the switch
						p(v-else="")
							i You may include the mapped params, however. keep in mind that this may drastically add more export time. To turn it <b>on</b> - go back to the main table and toggle the switch
				el-col(:span="24")
					el-form()
						el-row(:gutter="12")
							el-col(:xs=24 :sm="8" :md="6")
								el-form-item(label="Export All")
									el-radio-group.w-100.text-left(v-model="spreadsheet_export.export_all_flag" size="mini")
										el-radio-button(:label="true") On
										el-radio-button(:label="false") Off
							el-col(:xs=24 :sm="16" :md="18")
								el-form-item(v-if="!spreadsheet_export.export_all_flag" label="Columns to export")
									el-select.w-100(:collapse-tags="spreadsheet_export.columns.length > 7" multiple="" filterable="" v-model="spreadsheet_export.columns" size="mini" clearable="")
										el-option(v-for="o of spreadsheet_columns_options" :label="o.t" :value="o.v")
			span.dialog-footer(slot='footer')
				el-button(type='primary', @click='downloadExcel') Save
				el-button(type='danger', @click='showExcelExportModal = false') Close
		el-dialog.override-width.search-dialog(v-if="showCommentsModal" :visible="showCommentsModal" @close='showCommentsModal = false')
			el-row()
				el-col(:span="24")
					h5 Lead Comments
			el-row(:gutter="24")
				el-col(:xs="24")
					.infinite-list-wrapper(style="overflow:auto;" v-bind:class="{'half-screen':currentComment.list.length > 0}")
						div.infinite-list( v-infinite-scroll="load" infinite-scroll-distance="500px")
							div(v-for="c of currentComment.list" )
								el-card.comments-container.m-1.text-left( shadow="hover" cover="" v-bind:id="c.id")
									template(slot="header")
										span
											b Topic: {{c.topic}}
									//label  <b>Topic: </b>{{c.topic}}
									p.text-left {{'#' + c.id }}: {{ c.content }}
									.d-flex.justify-content-between
										span
											b Created By:&nbsp;
											| {{ c.user.first_name + ' ' + c.user.last_name }}
										span {{ c.updatedAt }}
			el-divider
			el-form()
				el-row.mt-1(:gutter="12")
					el-col.text-left(:span="24")
						label Add new comment on your behalf:
					el-col(:xs="24" :md="12")
						el-form-item(:error="errors.c_topic.s ? errors.c_topic.m : ''" v-bind:class="{'is-invalid': errors.c_topic.s}")
							el-input(placeholder="Topic" v-model="commentsForm.topic" :maxlength="40"  size="mini")
					el-col(:span="24")
						el-form-item(:error="errors.c_content.s ? errors.c_content.m : ''" v-bind:class="{'is-invalid': errors.c_content.s}")
							el-input(placeholder="'Write comments here (Minimum 2 characters)'" type="textarea" v-model="commentsForm.content" :rows="5" :maxlength="3000" size="mini")
							label.small-text {{commentsForm.content.length}} of 3000
			span.dialog-footer(slot='footer')
				el-button(type='primary', @click='saveComment') Save
				el-button(type='danger', @click='showCommentsModal = false') Close

		el-dialog.override-argin(v-if="currentChosenLead" :title="`Lead Details - #${currentChosenLead.id}`", :visible.sync="showLeadModal", center=""  width='90%')
			//perfect-scrollbar(:options="{suppressScrollX : true}")
			el-form
				el-row()
					h5 Basic Details
				el-row(:gutter="12")
					el-col(:span="12")
						el-form-item(label="Internal #")
							el-input(:value="currentChosenLead.id || '-'" readonly)

					el-col(:span="12")
						el-form-item(label="Broker CID")
							el-input(:value="currentChosenLead.cid || '-'" readonly)

					el-col(:span="12")
						el-form-item(label="First Name")
							el-input(:value="currentChosenLead.first_name" readonly)
					el-col(:span="12")
						el-form-item(label="Last Name")
							el-input(:value="currentChosenLead.last_name" readonly)
					el-col(:span="24")
						el-form-item(label="E-Mail")
							el-input(:value="currentChosenLead.email" readonly)
					el-col(:span="12")
						el-form-item(label="Phone")
							el-input(:value="currentChosenLead.phone" readonly)
					el-col(:span="12")
						el-form-item(label="Country")
							el-input(:value="currentChosenLead.country_object ? currentChosenLead.country_object.name : '-'" readonly)
				el-row()
					h5 Lead's Statuses
				el-row(:gutter="12")
					el-col(:span="12" v-if="permit(2)")
					el-col(:span="12" v-if="permit(2)")
						el-form-item(label="Original Status")
							el-input(:value="currentChosenLead.conv_status || '-'" readonly)
					el-col(:span="12" v-if="permit(2)")
						el-form-item(label="Broker Status")
							el-input(:value="currentChosenLead.original_status || '-'" readonly)

					el-col(:span="12")
						el-form-item(label="FTD")
							el-radio-group(:value="currentChosenLead.ftd" :readonly="true")
								el-radio(:label="true") True
								el-radio(:label="false") False

					el-col(:span="24" v-if="currentChosenLead.ftd")
						el-form-item(label="FTD Date")
							el-input(:value="currentChosenLead.ftd_date")

					el-col(:span="24")
						el-form-item(label="Registration Date")
							el-input(:value="currentChosenLead.date_created")
							b-calendar.w-100(:readonly="true" :value="currentChosenLead.date_only_created_raw" width="100%" )
				el-divider
				el-row()
					.d-flex.justify-content-between
						h5 Integration Details
						font-awesome-icon.p-0(:style="{width: 20, height: 20}" v-b-tooltip.html.hover-right="", :title="'Type: ' + currentChosenLead.source_app", size="lg", :icon="[currentChosenLead.source_app === 'IFRAME' ? 'fab' : 'far', currentChosenLead.source_app === 'IFRAME' ? 'internet-explorer' : 'file-excel']")

				el-row(:gutter="12")
					el-col(:span="12" )
						el-form-item(label="Brand")
							el-input(:value="currentChosenLead.brand ? (currentChosenLead.brand.id + ' - ' + currentChosenLead.brand.name) : '-'" readonly="")
					el-col(:span="12" )
						el-form-item(label="Global Brand")
							el-input(:value="currentChosenLead.brand ? (currentChosenLead.brand.global_name) : '-'" readonly="")
					el-col(:span="24" v-if="currentChosenLead.source_app === 'IFRAME' || currentChosenLead.source_app === 'FLOW'")
						el-form-item(label="Funnel")
							el-input(v-if="currentChosenLead.funnel_id && currentChosenLead.funnel" :value="currentChosenLead.funnel.id + ' - ' + currentChosenLead.funnel.name + (currentChosenLead.funnel.note ? ' - ' + currentChosenLead.funnel.note : '')" readonly="")
					el-col(:span="24" v-if="currentChosenLead.source_app === 'GOOGLE_SHEETS'")
						el-form-item(label="Funnel")
							.d-flex.justify-content-between.w-100()
								el-input(v-if="currentChosenLead.spreadsheet && currentChosenLead.spreadsheet" :value="currentChosenLead.spreadsheet.hash" readonly )
								el-button.el-icon-share.ml-1(v-if="currentChosenLead.spreadsheet" @click="openLink(`${link}${currentChosenLead.spreadsheet.hash}/edit#gid=0`)")
					el-col(:span="12")
						el-form-item(label="Funnel Family Name")
							el-input(:value="currentChosenLead.original_funnel_family_object ? (currentChosenLead.original_funnel_family_object.id + ' - ' + currentChosenLead.original_funnel_family_object.name) : 'Unclassified'")
					el-col(:span="12")
						el-form-item(label="Funnel Family - Real Funnel Name")
							el-input(:value="currentChosenLead.original_funnel_family_object ? currentChosenLead.original_funnel_family_object.real_funnel_name : 'Unclassified'")
					el-col(:span="24")
						el-form-item(label="Integration")
							el-input(v-if="currentChosenLead.original_integration_id && currentChosenLead.original_integration" :value="currentChosenLead.original_integration.id + ' - ' + currentChosenLead.original_integration.type + (currentChosenLead.original_integration.note ? ' - ' + currentChosenLead.original_integration.note : '')" readonly="")


				el-divider
				el-row()
					h5 Tracking Params
				el-row(:gutter="12" v-if="permit(1)")
					el-col(v-for='r of paramsArray', :span="24")
						el-form-item(:label='r.t', label-position='left')
							el-input(type='textarea', v-model='r.v', read-only='')

				el-row(:gutter="12" v-if="permit(1)")
					el-col( :span="24")
						el-form-item(label='Traffic Source', label-position='left')
							el-input( :value="currentChosenLead.real_source || '*Other'", read-only='')
							//el-input( :value="currentChosenLead.params ? currentChosenLead.params.real_source : '*Other'", read-only='')

				el-row
					h5 Meta Data
				el-row
					el-col(v-if="currentMeta.length===0" :span="24")
						h5.text-center Unfortunately there is no meta data available for this lead
					el-col(v-for='r of currentMeta', :span="24")
						el-form-item(:label='r.key', label-position='top',)
							el-input(type='textarea', :value='r.value', read-only='', rows="5")


			span(slot="footer" class="dialog-footer")
				el-row(:gutter="12")
					el-col(:span="8")
						el-button.form-control.mt-3(v-if="ccm" @click="showLeadModal = false" type="success") Close
					el-col(:span="8")
						el-button.form-control.mt-3(v-if="ccsc" v-b-tooltip.html.hover-top="", title="Comments", type="info", @click="showComments(currentChosenLead)") MSG
					el-col(:span="8")
						el-button.form-control.mt-3(v-if="!currentChosenLead.ftd && currentChosenLead.phone_number && cftd" v-b-tooltip.html.hover-top="", title="Toggle FTD", type="", @click="toggleFtd(currentChosenLead.uuid)") FTD

					el-col(:span="8")
						el-button.form-control.mt-3(v-if="permit(2) && !currentChosenLead.cid && currentChosenLead.phone_number" v-b-tooltip.html.hover.top="", title="Retry Register", type="warning",  icon="el-icon-refresh", @click="toggleReregisterModal(currentChosenLead)")
					el-col(:span="8")
						el-button.form-control.mt-3( @click="geoIpGuess(currentChosenLead.ip_addr)" type="info") IP
					el-col(:span="8")
						el-button.form-control.mt-3( v-if="permit(1)" @click="() => {this.removeEntity(currentChosenLead.id); this.showLeadModal = !this.showLeadModal}" type="danger") Delete

		el-dialog.override-argin.override-width.search-dialog(title='IP Info Params', :visible.sync='ipDialogShown', width='75%', center='' @closed="currentGeoData = []")
			span
				h5 IP Geo-Location - {{currentIP}}
			el-divider
			el-form.mt-3
				el-row(:gutter='10')
					//pre {{currentGeoData}}
					el-col(v-if="currentGeoData.length === 0")
						h5.pb-2 This IP Could not be resolved
					el-col(v-else="" v-for="r of currentGeoData" :span="24")
						h5.pb-2
							b {{r.topic.toUpperCase()}}:&nbsp;
							label.ml-3(v-if="r.content!=='MISSING'") {{ r.content}}
							label.ml-3.text-danger(v-else="")
								i {{r.content}}
						//el-form-item(v-for="l of r.content" :label='l[0]', label-position='left', label-width='7rem')
							el-input(type='text', :value='l[1]', read-only='')
			span.dialog-footer(slot='footer')
				el-button(type='primary', @click='ipDialogShown = false') Ok

		el-dialog.override-width.search-dialog(title="Failed Lead Reassign Queue" :visible.sync="reassignQueModal" width="70%")
			el-row
				el-col(:span="24")
					el-row(:gutter="48")
						el-col(:xs="24" :md="12")
							.text-left
								h5 Notice
								el-divider
								p.letter-long The purpose of this tool is to 'route' the customer to another broker in case of registration failure. Be careful with it.
								p.letter-long Clicking on "Save" button will add the lead to a queue and will try to register it to the predefined brand and funnel, while clicking on "Retry" will try to register it to the same brand and funnel that the lead is currently set to.
								p.letter-long You must set the desired interval between each registrations <b>(in minutes)</b> and may set a password on this form. If you leave it blank, <small style="color:red">"a random password"</small> will be used. "@!" signs will be added if it was set to add a sign at the integration level.
								p.letter-long Adding the lead to the queue mechanism will check the destination brand and funnel, and assign it to the corresponding brand and integration of the lead (if set to 'Retry'), or to those specified in the form (if set to 'Save'). With each failed registration attempt, the system will verify whether it has reached the maximum number of attempts (fewer than 3 retries) and will adjust the timer for the next attempt to the end of the queue.
						el-col(:xs="24" :md="12")
							.text-left
								h5 Assignation
								el-divider
							el-form(v-if="currentChosenLead || current_action === 'multi-user'" autocomplete="nope")
								el-row(:gutter="12")
									el-col(:span="24")
										.text-left
											label Original Brand:&nbsp;&nbsp;&nbsp;
												span.ml-1 <b>{{ currentChosenLead ? currentChosenLead.brand.name : 'Multi Brand' }}</b>
									//el-col.text-left(:span="24")
										el-form-item(label="Grouping Name" label-position="top" label-width="auto")
											el-input(placeholder="Grouping Name" v-model="assign.grouping" size="mini" type="text" autocomplete="nope" )
											small.text-danger.font-italic * Leave blank to autogenerate

									el-col(:xs="24" :sm="12" )
										el-form-item(label="New Brand:" label-position="top" label-width="auto")
											el-select.w-100(v-bind:id="randId()" filterable="", placeholder="E.g Galore" v-model="assign.brand" size="mini" autocomplete="nope" type="text" )
												el-option(v-for="o of brands_options" :value="o.v" :label="o.t")
									el-col(:xs="24" :sm="12" )
										el-form-item(label="New Funnel:" label-position="top" label-width="auto" )
											el-select.w-100(:id="randId()" filterable="", placeholder="E.g 1KDailyProfits" v-model="assign.funnel" size="mini" autocomplete="nope" :disabled="assign.brand===''" )
												el-option(v-for="o of funnels_options" :value="o.v" :label="o.t + (o.nt && o.nt !== 'classified' ? ` - ${o.nt}` : '')" v-if="o.b === assign.brand" )
									el-col(:xs="24" :sm="12" )
										el-form-item(label="Password:" label-position="top" label-width="auto" :error="errors.password.s ? errors.password.m : ''" v-bind:class="{'is-invalid': errors.password.s}")
											el-input(:id="randId()" placeholder="8 Chars, 1UC, 1LC, 1SYM" v-model="assign.password" size="mini" type="password" autocomplete="nope" show-password="")
									el-col(:xs="24" :sm="12")
										el-form-item(label="Interval (Minutes):" label-position="top" label-width="auto" )
											el-input-number.w-100(placeholder="Interval in minutes" :steps="1" size="mini" v-model="executionInterval" :min="1" :max="5000")

			span.dialog-footer(slot='footer')
				.d-flex.justify-content-center
					el-button(type='primary', @click="addLeadsToQue('reassign')" :disabled="assign.brand==='' || assign.funnel===''" :loading="reassignBusy") Save
					el-button(type='info', @click="addLeadsToQue('re-register')" :disabled="reassignBusy" :loading="reassignBusy") Retry
					el-button(type='danger', @click='reassignQueModal=!reassignQueModal') cancel


		el-dialog.override-width.search-dialog(title="Reassign Queue Lead List" :visible.sync="showQueueListModal" width="85%")
			reassign-queue(v-if="showQueueListModal" :brands_options="brands_options" :funnels_options="funnels_options")

		el-dialog.override-width.search-dialog(title="Request Reports" :visible.sync="showRequestsModal"  width='85%', center='' )
			requests-report(v-if="showRequestsModal")

		el-dialog.override-width.search-dialog(title="Re-Register To Another Broker" :visible.sync="reregisterModal" width="70%")
			el-row(:gutter="48")
				el-col(:xs="24" :md="12")
					.text-left
						h5 Notice
						el-divider
						p.letter-long The purpose of this tool is to 'route' the customer to another broker in case of registration failure. Be careful with it.
						p.letter-long Clicking on "Save" button will trigger registration process. You may set a password on this form. If you leave it blank, <small style="color:red">"a random password"</small> will be used. A "@" sign will be added if it was set to add a sign at the integration level.
						p.letter-long You can also toggle re-registration using this dialog - click on "Retry" button

				el-col(:xs="24" :md="12")
					.text-left
						h5.d-inline Assignation
						span.text-primary.chooseable.d-inline.float-right(@click="copyFiledOverrideFlag = !copyFiledOverrideFlag" v-bind:class="{'text-primary': !copyFiledOverrideFlag, 'text-danger': copyFiledOverrideFlag}") {{copyFiledOverrideFlag ? 'Hide Fields ' : 'Edit Props '}}
							font-awesome-icon.icon.alt.big-icon.mb-0(size="lg", :icon="['fa', 'users']")
					el-divider
					el-form(v-if="currentChosenLead || current_action === 'multi-user'" autocomplete="nope" ref="assign" :model="assign" :rules="assignRules")
						el-row(:gutter="12")
							el-col(:span="24")
								.text-left
									label Original Brand:&nbsp;&nbsp;&nbsp;
										span.ml-1 <b>{{ currentChosenLead ? currentChosenLead.brand.name : 'Multi Brand' }}</b>
							el-col(:xs=24 :sm="12" :md="8")
								el-form-item(label="New Brand:" label-position="top" label-width="auto")
									//el-select.w-100(v-bind:id="randId()" filterable="", placeholder="E.g Galore" v-model="assign.brand" size="mini" autocomplete="nope" type="text" )
									el-select.w-100( filterable="", placeholder="E.g Galore" v-model="assign.brand" size="mini" autocomplete="nope" )
										el-option(v-for="o of brands_options" :value="o.v" :label="o.t")
							el-col(:xs=24 :sm="12" :md="8")
								el-form-item(label="New Funnel:" label-position="top" label-width="auto" )
									//el-select.w-100(:id="randId" filterable="", placeholder="E.g 1KDailyProfits" v-model="assign.funnel" size="mini" autocomplete="nope" :disabled="assign.brand===''" )
									el-select.w-100(filterable="", placeholder="E.g 1KDailyProfits" v-model="assign.funnel" size="mini" autocomplete="nope" :disabled="assign.brand===''" )
										el-option(v-for="o of funnels_options" :value="o.v" :label="o.t + (o.nt && o.nt !== 'classified' ? ` - ${o.nt}` : '')" v-if="o.b === assign.brand" )
							el-col(:xs=24 :sm="12" :md="8")
								el-form-item(label="Password:" label-position="top" label-width="auto" :error="errors.password.s ? errors.password.m : ''" v-bind:class="{'is-invalid': errors.password.s}")
									//el-input(:id="randId" placeholder="8 Chars, 1UC, 1LC, 1SC" v-model="assign.password" size="mini" type="password" autocomplete="nope" show-password="")
									el-input(placeholder="8 Chars, 1UC, 1LC, 1SC" v-model="assign.password" size="mini" type="password" autocomplete="nope" show-password="")
			el-divider(v-if="copyFiledOverrideFlag")
			el-row.text-left(v-if="copyFiledOverrideFlag")
				h5 Override Fields
				p Use the fields below to override the params of the lead when copying it to another brand.
			el-form.mt-3(v-if="copyFiledOverrideFlag" ref="assign2" :model="assign" :rules="assignRules")
				el-row(:gutter="24")
					el-col(:xs="12" :sm="6" :lg="6" v-if='current_action === "single-user"')
						el-form-item(label="First Name" label-position="top" prop="first_name" ref="_asgnFirstName")
							el-input(placeholder="John" size="mini"  v-model="assign.first_name")
					el-col(:xs="12" :sm="6" :lg="6" v-if='current_action === "single-user"')
						el-form-item(label="Last Name" label-position="top" prop="last_name" ref="_asgnLastName")
							el-input(placeholder="Smith"  size="mini"  v-model="assign.last_name")
					el-col(:xs="12" :sm="6" :lg="4" v-if='current_action === "single-user"')
						el-form-item(label="Phone Number" label-position="top" prop="phone_number" ref="_asgnPhoneNumber")
							el-input(placeholder="+4915123456789"  size="mini"  v-model="assign.phone_number")
					el-col(:xs="12" :sm="6" :lg="4")
						el-form-item(label="Country" label-position="top" prop="country" ref="_asgnCountry")
							el-select.w-100(filterable="",  size="mini"   placeholder='Russia'  v-model="assign.country")
								el-option.text-bold(label='All', value='')
								el-option(v-for="o of country_options", :label="o.t", :value="o.v")
					el-col(:xs="12" :sm="6" :lg="4")
						el-form-item(label="IP (v4)" label-position="top"  prop="ip_addr" ref="_asgnIpAddr")
							el-input(placeholder="188.166.12.22"  size="mini"  v-model="assign.ip_addr")
			span.dialog-footer(slot='footer')
				.d-flex.justify-content-center
					el-button(type='primary', @click='current_action === "single-user" ? reassignLeadToAnotherBrand() : reassignLeadToAnotherBrandBulk()' :disabled="assign.brand==='' || assign.funnel==='' || reassignBusy" :loading="reassignBusy") Save
					el-button(type='info', @click='current_action === "single-user" ? retryRegister(currentChosenLead.uuid) : retryRegisterBulk()' :disabled="reassignBusy" :loading="reassignBusy") Retry
					el-button(type='danger', @click='reregisterModal=!reregisterModal') cancel

		el-dialog.override-width.search-dialog(title="Copy To Another Broker" :visible.sync="copyModal" width="70%")
			el-row(:gutter="48")
				el-col(:xs="24" :md="12")
					.text-left
						h5 Notice
						el-divider
						p.letter-long The purpose of this tool is to 'copy' an existing lead to another broker. Be careful with it.
						p.letter-long Clicking on "Save" button will trigger registration process. You may set a password on this form. If you leave it blank, <small style="color:red">"a random password"</small> will be used. A "@" sign will be added if it was set to add a sign at the integration level.
						p.letter-long As opposite to reassign action, this action <b>does not</b> delete the original entry.

				el-col(:xs="24" :md="12")
					.text-left
						h5.d-inline Assignation
						span.text-primary.chooseable.d-inline.float-right(@click="copyFiledOverrideFlag = !copyFiledOverrideFlag" v-bind:class="{'text-primary': !copyFiledOverrideFlag, 'text-danger': copyFiledOverrideFlag}") {{copyFiledOverrideFlag ? 'Hide Fields ' : 'Edit Props '}}
							font-awesome-icon.icon.alt.big-icon.mb-0(size="lg", :icon="['fa', 'users']")
					el-divider
					el-form(v-if="currentChosenLead || current_action === 'multi-user'" autocomplete="nope" ref="assign" :model="assign" :rules="assignRules")
						el-row(:gutter="12")
							el-col(:span="24")
								.text-left
									label Original Brand:&nbsp;&nbsp;&nbsp;
										span.ml-1 <b>{{ currentChosenLead ? currentChosenLead.brand.name : 'Multi Brand' }}</b>


							el-col(:xs=24 :sm="12" :md="8")
								el-form-item(label="New Brand:" label-position="top" label-width="auto" has-feedback="" prop="brand"  ref="_asgnBrand")
									//el-select.w-100( filterable="", placeholder="E.g Galore" v-model="assign.brand" size="mini" autocomplete="nope" )
									el-select.w-100(filterable="", placeholder="E.g Galore" v-model="assign.brand" size="mini" autocomplete="nope" )
										el-option(v-for="o of brands_options" :value="o.v" :label="o.t")
							el-col(:xs=24 :sm="12" :md="8")
								el-form-item(label="New Funnel:" label-position="top" label-width="auto" has-feedback="" prop="funnel"  ref="_asgnFunnel")
									//el-select.w-100(:id="randId" filterable="", placeholder="E.g 1KDailyProfits" v-model="assign.funnel" size="mini" autocomplete="nope" :disabled="assign.brand===''" )
									el-select.w-100(filterable="", placeholder="E.g 1KDailyProfits" v-model="assign.funnel" size="mini" autocomplete="nope" :disabled="assign.brand===''" )
										el-option(v-for="o of funnels_options" :value="o.v" :label="o.t + (o.nt && o.nt !== 'classified' ? ` - ${o.nt}` : '')" v-if="o.b === assign.brand" )
							el-col(:xs=24 :sm="12" :md="8")
								el-form-item(label="Password:" label-position="top" label-width="auto" :error="errors.password.s ? errors.password.m : ''" v-bind:class="{'is-invalid': errors.password.s}" has-feedback="" prop="password")
									el-input(placeholder="8 Chars, 1UC, 1LC, 1SC" v-model="assign.password" size="mini" type="password" autocomplete="nope" show-password="")
									//el-input(:id="randId" placeholder="8 Chars, 1UC, 1LC, 1SC" v-model="assign.password" size="mini" type="password" autocomplete="nope" show-password="")
			el-divider(v-if="copyFiledOverrideFlag")
			el-row.text-left(v-if="copyFiledOverrideFlag")
				h5 Override Fields
				p Use the fields below to override the params of the lead when copying it to another brand.
			el-form.mt-3(v-if="copyFiledOverrideFlag" ref="assign2" :model="assign" :rules="assignRules")
				el-row(:gutter="24")
					el-col(:xs="12" :sm="6" :lg="6" v-if='current_action === "single-user"')
						el-form-item(label="First Name" label-position="top" prop="first_name" ref="_asgnFirstName")
							el-input(placeholder="John" size="mini"  v-model="assign.first_name")
					el-col(:xs="12" :sm="6" :lg="6" v-if='current_action === "single-user"')
						el-form-item(label="Last Name" label-position="top" prop="last_name" ref="_asgnLastName")
							el-input(placeholder="Smith"  size="mini"  v-model="assign.last_name")
					el-col(:xs="12" :sm="6" :lg="4" v-if='current_action === "single-user"')
						el-form-item(label="Phone Number" label-position="top" prop="phone_number" ref="_asgnPhoneNumber")
							el-input(placeholder="+4915123456789"  size="mini"  v-model="assign.phone_number")
					el-col(:xs="12" :sm="6" :lg="4")
						el-form-item(label="Country" label-position="top" prop="country" ref="_asgnCountry")
							el-select.w-100(filterable="",  size="mini"   placeholder='Russia'  v-model="assign.country")
								el-option.text-bold(label='All', value='')
								el-option(v-for="o of country_options", :label="o.t", :value="o.v")
					el-col(:xs="12" :sm="6" :lg="4")
						el-form-item(label="IP (v4)" label-position="top"  prop="ip_addr" ref="_asgnIpAddr")
							el-input(placeholder="188.166.12.22"  size="mini"  v-model="assign.ip_addr")
			span.dialog-footer(slot='footer')
				.d-flex.justify-content-center
					//el-button(type='primary', @click='current_action === "single-user" ? copyLeadToAnotherBrand() : copyLeadToAnotherBrandBulk()' :disabled="assign.brand==='' || assign.funnel==='' || reassignBusy" :loading="reassignBusy") Save
					el-button(type='primary', @click='current_action === "single-user" ? copyLeadToAnotherBrand() : copyLeadToAnotherBrandBulk()' :disabled="assign.brand==='' || assign.funnel==='' || reassignBusy" :loading="reassignBusy") Save
					el-button(type='danger', @click='copyModal=!copyModal') cancel

		el-dialog.override-width.search-dialog(title='Tracking Params', :visible.sync='brokerDataDialogShown', width='30%', center='')
			span
				b Broker's Data

		el-dialog.override-width.search-dialog(title='Reference Params', :visible.sync='referenceLeadModal', width='85%', center='')
			el-row
				b Reference Lead - Original lead
				el-form.mt-3()
					el-row(:gutter='10')
						el-col(:span="24" v-if="currentReferenceLead.length === 0")
							| No params
						el-col.mt-3(v-else="" v-for='r of currentReferenceLead', :span="12")
							//el-form-item(:label='r[0] + ":"', label-position='left')
							span.font-weight-bold {{r[0]}}:&nbsp;
							span.text-danger {{r[1]}}
			span.dialog-footer(slot='footer')
				el-button(type='danger', @click='referenceLeadModal = false') Close

		el-dialog.override-width.search-dialog(title="visits" :visible.sync='showVisitsModal', width='85%', center='' @closed="currentGeoData = []")
			perfect-scrollbar(:options="{suppressScrollX : true}")
				visits-list(v-if="showVisitsModal", :steps_options="steps_options", :country_options="country_options", :brands_options="brands_options", :funnels_options="funnels_options")
			//el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='visits.list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')

		el-dialog.override-width.search-dialog(title="Visits - Reports" :visible.sync='showVisitsReportModal', width='85%', center='' @closed="currentGeoData = []")
			perfect-scrollbar(:options="{suppressScrollX : true}")
				visits-report(v-if="showVisitsReportModal")

		el-dialog.override-width.search-dialog(title="Update lead's details form" :visible.sync='showDetailsUpdateModal', width='80%', center='' )
			lead-update(ref="details_update_modal" v-if="showDetailsUpdateModal" :details="currentChosenLead" :country_options="country_options" :traffic_source_options="traffic_source_options" :bulk="bulkLeadEditFlag" :selected="multipleSelectionUUID" @success="updateDetailsSuccess")
			template(slot="footer")
				el-button(type='primary', @click='submitUpdateDetailsModal') Save
				el-button(type='danger', @click='showDetailsUpdateModal = false') Close


		el-dialog(:visible.sync="showColumnSelectModal" :title="'Select columns to show'")
			el-form
				h5 Select the columns that you want to show in this page
					el-row.m-3(:gutter="12")
						//pre {{ selectedColumns[currentTab] }}
						el-col(v-for="title of Object.keys(selectedColumns).sort()" :span="6")
							el-checkbox.w-100.text-left(:label="title" :key="title" @change="columnSelectedHook(title)" v-model="selectedColumns[title]") {{ title }}
			template(slot="footer")
				el-button(type="danger" @click="showColumnSelectModal = !showColumnSelectModal") Close

</template>

<style lang="scss">

.el-table .warning-row, .el-table__row.el-table__row--striped.warning-row {
	background: oldlace !important;
}

.half-screen {
	height:35vh
}

.letter-long {
	overflow-wrap: anywhere;
	word-break: break-word;
}

.param-delete-class {
		padding-left: 0;
		margin-left: 0.25rem;
}

.comments-container {
	font-size: 12px !important;
	background-color: #f7f9fa;
	&:hover {
		background-color: #ebf1f3
	}
	.el-card__header {
		padding-top: 0.4rem;
		padding-bottom: 0.2rem;
	}
}

.small-btn-action.search-action-btn {
	//padding: 9px 11px
	padding: 6.5px;
	margin-left: 0.3rem !important;
	&:first-child {
		margin-left: 0;
	}
}

	.override-width.search-dialog {
		@media only screen and (max-width: 767px) {
			.el-dialog {
				width: 90% !important;
			}
		}
	}
	.override-argin {
		.el-dialog {
			margin-top: 9vh !important;
		}

		.ps {
			/*height: 65vh*/
		}
	}

	.lead-card {
		cursor: pointer;

		* {
			cursor: pointer;
		}

		background-color: #fafafa;


		.el-card__header {
			background-color: #f6f6fc;
		}

		.el-form-item {
			margin-bottom: 0;

			.el-form-item__label {
				line-height: 20px;
			}

			.el-form-item__content {
				line-height: 20px;
			}
		}

		&:hover {
			.el-card__header {
				background-color: #eaecef;
			}

			background-color: #f1f3f5;
		}
	}
</style>

<style lang="scss" scoped>
.info-box {
	font-size: 12px;
}
</style>


<script lang="js">
	import visits from '../components/visits'
	import VisitsList from "../components/visits";
	import VisitsReport from "../components/visit-report";
	import LeadUpdate from "../components/lead-update";
	import ReassignQueue from "@/components/reassignQueue";
	import CustHorizScroll from "@/components/custom-horizontal-scroll.vue";
	import {
		notEmpty,
		validateAlphaNumeric,
		validateCountryIso, validateIP,
		validateNumeric,
		validatePass,
		validateSelect
	} from "../utils/validate";
	import RequestsReport from "../components/request-report";
	import moment from 'moment';

	const clearNulls = (data) => {
		if (data.brand == -1) data.brand = '';
		if (data.funnel_family == -1) data.funnel_family = '';
		if (data.original_status === 'all') data.original_status = '';
		if (!data.range) delete data.range
		if (!data.ftd_date_range) delete data.ftd_date_range
		return data;
	}

	const $originalFilters = {
		params: '',
		// range: null,
		range: [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
		type: 'all',
		brand: '',
		funnel: '',
		funnel_family: '',
		spreadsheet: '',
		campaign: '',
		source_app: '',
		country: '',
		ftd_date_range: null,
		wo_comments: '0',
		original_status: '',
		failed_reason: '',
		language: '',
		traffic_source: '',
		deleted: 0,
		affiliate_id: '',
		network_id: '',
		product_sku: '',
		brand_assignee: '',
		params_affiliate_id: '',
		join_mapped_params: 0
	}

	export default {
			filters: {
					t_status_param(status) {
							const statusMap = {
									none: 'danger',
									'No Value Set...': 'danger',
									pending: 'info',
							};
							return statusMap[status]
					},

					status: ''
			},
		components: {RequestsReport, ReassignQueue, VisitsList, visits, VisitsReport, LeadUpdate, CustHorizScroll },
		data() {
			return {
				refreshLeadFlag: +(this.$store.getters.AUTO_REFRESH_LEADS),
				filtersLockFlag: this.$store.getters.LOCK_SEARCH_FILTERS || '-1',
				joinMappedParamsFlag: 0,
				bulkLeadEditFlag: false,
				showDetailsUpdateModal: false,
				showExcelExportModal: false,
				editModeCount: 0,
				currentLeadUUIDEditMode: null,
				showRequestsModal: false,
				copyFiledOverrideFlag: false,
				showColumnSelectModal: false,
				block: false,
				fetchWatcher: null,
				busy: false,
				reassignBusy: false,
				reregisterModal: false,
				copyModal: false,
				tableExtended: false,
				brokerDataDialogShown: false,
				showLeadModal: false,
				showVisitsModal: false,
				showVisitsReportModal: false,
				showQueueListModal: false,
				showCommentsModal: false,
				referenceLeadModal: false,
				currentReferenceLead: {},
				currentChosenLead: null,
				currentGeoData: [],
				currentIP: '',
				paramsDialogShown: false,
				metaDialogShown: false,
				ipDialogShown: false,
				selectedColumns: {},
				currentBrokerData: [],
				currentParams: [],
				currentMeta: [],
				assign: {
					brand: '',
					funnel: '',
					password: '',
					first_name: '',
					last_name: '',
					phone_number: '',
					country: '',
					ip_addr: '',
					//grouping: ''
				},
				assignRules: {
					brand: { required: true, validator: validateAlphaNumeric, trigger: 'blur' },
					funnel: { required: true, validator: validateAlphaNumeric, trigger: 'blur' },
					password: { required: false, validator: validatePass, trigger: 'blur' },
					first_name: { required: false, validator: notEmpty, trigger: 'blur' },
					last_name: { required: false, validator: notEmpty, trigger: 'blur' },
					phone_number: { required: false, validator: validateNumeric, trigger: 'blur' },
					country: { required: false, validator: validateCountryIso, trigger: 'blur' },
					ip_addr: { required: false, validator: validateIP, trigger: 'blur' },
				},
				executionInterval: 10,
				reassignQueModal: false,
				paginate: {
					keyword: '',
					limit: 20,
					page: 1
				},
				currentCommentPaginate: {
					keyword: '',
					limit: 100,
					page: 1
				},
				currentComment: {
					list: [],
					count: 0
				},
				commentsForm: {
					topic: '',
					content: ''
				},
				filters: {
					params: this.$store.getters.CUR_SEARCH_FILTERS.params || '',
					// range: null,
					range: this.$store.getters.CUR_SEARCH_FILTERS.range || [moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
					type: this.$store.getters.CUR_SEARCH_FILTERS.type || 'all',
					brand: this.$store.getters.CUR_SEARCH_FILTERS.brand || '',
					funnel: this.$store.getters.CUR_SEARCH_FILTERS.funnel || '',
					funnel_family: this.$store.getters.CUR_SEARCH_FILTERS.funnel_family || '',
					spreadsheet: this.$store.getters.CUR_SEARCH_FILTERS.spreadsheet || '',
					campaign: this.$store.getters.CUR_SEARCH_FILTERS.campaign || '',
					source_app: this.$store.getters.CUR_SEARCH_FILTERS.source_app || '',
					country: this.$store.getters.CUR_SEARCH_FILTERS.country || '',
					ftd_date_range: this.$store.getters.CUR_SEARCH_FILTERS.ftd_date_range || null,
					wo_comments: this.$store.getters.CUR_SEARCH_FILTERS.wo_comments || '0',
					failed_reason: this.$store.getters.CUR_SEARCH_FILTERS.failed_reason || '',
					original_status: this.$store.getters.CUR_SEARCH_FILTERS.original_status || '',
					language: this.$store.getters.CUR_SEARCH_FILTERS.language || '',
					traffic_source: this.$store.getters.CUR_SEARCH_FILTERS.traffic_source || '',
					deleted: this.$store.getters.CUR_SEARCH_FILTERS.deleted || 0,
          affiliate_id: this.$store.getters.CUR_SEARCH_FILTERS.affiliate_id || '',
          network_id: this.$store.getters.CUR_SEARCH_FILTERS.network_id || '',
          product_sku: this.$store.getters.CUR_SEARCH_FILTERS.network_id || '',
					brand_assignee: this.$store.getters.CUR_SEARCH_FILTERS.brand_assignee || '',
					params_affiliate_id: this.$store.getters.CUR_SEARCH_FILTERS.params_affiliate_id || '',
					join_mapped_params: this.$store.getters.CUR_SEARCH_FILTERS.params_affiliate_id || 0,

				},
				list: [],
				count: 0,
				ftds: 0,
				conv_rate: 0,
				visits: {
					list: [],
					count: 0,
					paginate: {
						keyword: '',
						limit: 20,
						page: 1
					}
				},
				errors: {
					password: { s: false, m: '' },
					c_topic: { s: false, m: '' },
					c_content: { s: false, m: '' },
				},
				spreadsheet_export: {
						columns: [],
						export_all_flag: true,
				},

				originalColumns: {},
				customer_view_types: [],
        affiliates_options: [],
				brands_options: [],
				funnels_options: [],
				spreadsheet_options: [],
				campaign_options: [],
				failed_reason_options: [],
				origin_options: [],
				funnel_families_options: [],
				status_options: [],
				language_options: [],
				network_options: [],
				traffic_source_options: [],
				country_options: [],
				user_options: [],
				brand_owners_options: [],
				steps_options: [],
				spreadsheet_columns_options: [],
				spreadsheet_columns_list: [],
				multipleSelection: [],
				reassign_leads_arr: [],
				copyModal_leads_arr: [],
				reassign_leads_que_arr: [],
				copyModal_leads_que_arr: [],
				current_action: '',
				traffic_source_hash: {},
				countries_hash_basic: {},
				network_hash: {},
				language_hash: {},
				pickerOptions: {
					shortcuts: [
						{
							text: 'Today',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime());
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last 2 Days',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last week',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last month',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last 3 months',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit('pick', [start, end]);
							}
						}
					]
				},
			}
		},
		created() {
			this.setColumns();
		},
		async mounted() {
				const $csboOpt = this.$store.getters.CSBO ? '6e1430cf-6c24-4da7-aeaf-a63579441e45,': '';
			let result = await this.$apix.sendHttpRequest('GET', 'components/options', {filters: `${$csboOpt}${this.permit(2) ? '018e81d9-3eb1-7f9e-8464-b7fb09b5e357,92c2c776-0d3b-4ad0-bae8-9a2905d53bdb,95e5744a-9964-42d4-ae26-c3e591ecb7e0,' : ''}cf501858-15e4-4bd9-90df-aff9ae761249,2e7b1fea-eba8-460d-bd61-96fae4d45d25,e91556b8-c5da-442f-a0bf-31f2ce567abe,7141fd9b-916b-4788-b7a6-b87d19b6438a,b19ead9e-fed0-4653-b88b-291021017ce3,250b1af9-9bb3-4591-b641-fa54e1030a93,1e0c327a-73cf-4159-b154-ec498ade6dcd,49f06cec-46b0-4237-bf9a-d4d3969e5af6,d1a1e354-cd70-4401-b242-b7e8db62e857,b46a2973-5f33-4d57-8bc2-3eb96bb26ad2,1ef2b5d2-7c11-4369-a1c8-59e74e01c252,0661e754-6a3a-4abe-842d-975a2d41a871,eade7b54-f499-4265-925b-12691f7ea2e5,8ffae361-a8e2-4694-81a1-fa1803be7131,6938b0d4-7b9c-46ae-8fb5-3cb7285c6e02`});
			const language_hash = {};
			result.languages.forEach(lang => language_hash[lang.v] = lang.t);
			this.language_hash = {...language_hash};

			console.log('---------==============', this.language_hash );
			this.fetchData();
			this.countries_hash_basic = result.countries_hash_basic;
			this.customer_view_types = result.customer_view_types;
			this.funnel_families_options = result.funnel_families;
			this.failed_reason_options = result.failed_reasons;
			this.origin_options = result.origin;
			this.brands_options = result.brand;
			this.affiliates_options = result.affiliates;
			this.spreadsheet_options = result.spreadsheets;
			this.campaigns_options = result.campaigns_options;
			this.language_options = result.languages;
			this.network_options = result.network_options;
			this.traffic_source_options = result.traffic_sources;
			this.status_options = result.status_options;
			this.funnels_options = result.funnel_extra;
			this.country_options = result.countries;
			this.user_options = result.users;
			this.brand_owners_options = result.brand_owners;
			if(result.xlsx_columns && Array.isArray(result.xlsx_columns)) this.spreadsheet_columns_options = result.xlsx_columns;
			if(result.xlsx_columns && Array.isArray(result.xlsx_columns)) this.spreadsheet_columns_list = result.xlsx_columns.map(e => e.t)
			this.steps_options = result.steps;
			this.traffic_source_options.forEach((e) => this.traffic_source_hash[e.v] = e)
			this.network_options.forEach((e) => this.network_hash[e.v] = e)
			this.fetchWatcher = setInterval(() => {
				if (this.block || this.refreshLeadFlag === -1 || this.refreshLeadFlag === 0) return;
				this.fetchData();
			}, 60000);


		},
		beforeDestroy() {
			clearInterval(this.fetchWatcher)
		},
		computed: {
			// assignFormValid() {   // someday fix it
			// 	return 	this.copyFiledOverrideFlag &&
			// 		this.$refs._asgnBrand && this.assign.brand !== '' && this.$refs._asgnBrand._self.validateState !== 'error' &&
			// 		this.$refs._asgnFunnel && this.assign.funnel !== '' && this.$refs._asgnFunnel._self.validateState !== 'error' &&
			// 		this.$refs._asgnFirstName && this.assign.first_name !== '' && this.$refs._asgnFirstName._self.validateState !== 'error' &&
			// 		this.$refs._asgnLastName && this.assign.last_name !== '' && this.$refs._asgnLastName._self.validateState  !== 'error' &&
			// 		this.$refs._asgnPhoneNumber && this.assign.phone_number !== '' && this.$refs._asgnPhoneNumber._self.validateState  !== 'error' &&
			// 		this.$refs._asgnCountry && this.assign.country !== '' && this.$refs._asgnCountry._self.validateState  !== 'error' &&
			// 		this.$refs._asgnIpAddr && this.assign.ip_addr !== '' && this.$refs._asgnIpAddr._self.validateState !== 'error';
			// },
			multipleSelectionUUID() {
				return this.multipleSelection.map(e => e.uuid)
			},
			lockSearchFiltersCpt() {
				// console.log(this.$store.getters.LOCK_SEARCH_FILTERS)
				return this.$store.getters.LOCK_SEARCH_FILTERS == '1';
			},
			ccl() {
				return this.$store.getters.CCL == 'true';
			},
			csbo() {
				return this.$store.getters.CSBO == 'true';
			},
			crl() {
				return this.$store.getters.CRL == 'true';
			},
			ctf() {
				return this.$store.getters.CTF == 'true';
			},
			cdex() {
				return this.$store.getters.CDEX == 'true';
			},
			cvd() {
				return this.$store.getters.CVD == 'true';
			},
			cets() {
				return this.$store.getters.CETS == 'true';
			},
			ced() {
				return this.$store.getters.CED == 'true'
			},
			ccm() {
				return this.$store.getters.CCM == 'true'
			},
			ccsc() {
				return this.$store.getters.CCSC == 'true'
			},
			cftd() {
				return this.$store.getters.CFTD == 'true'
			},
			sol() {
				return this.$store.getters.SOL == 'true'
			},
			cspa() {
				return this.$store.getters.CSPA == 'true'
			},
			csmp() {
				return this.$store.getters.CSMP == 'true'
			},
			selectedColumnsHash() {
				return this.$store.getters.SELECTED_COLUMNS_MAIN_TBL
			},
			baseUrl() {
				return process.env.VUE_APP_BASE_URL;
			},
			link() {
				return process.env.VUE_APP_GOOGLE_SHEETS_URL || 'https://docs.google.com/spreadsheets/d/'
			},
			paramsArray() {
				let currentParams = [];
				Object.entries(this.currentChosenLead.params).forEach((e, i) => {
					currentParams.push({t: e[0], v: e[1]})
				});
				return currentParams;
			},
		},
		methods: {
			changeRefreshFlag() {
				this.$store.dispatch('setAutoRefreshLeadFlag', this.refreshLeadFlag);
			},
			changeLockFilterFlag() {
				this.$store.dispatch('setLckSearchFilters', this.filtersLockFlag)
					.then(() => {
						if(this.filtersLockFlag == '-1') this.$store.dispatch('removeSearchFilters')
				})
			},
			async handleShowChange(id, uuid, val) {
				try {
					const result = await this.$apix.sendHttpRequest('PUT', `lead/param/show_to_affiliate`, { uuid, val })
					if (result && result[0] === 1) {
						let state = val ? 'shown' : 'hidden'
						this.$notify.success({title: 'API Response', message: `The lead is now ${state} to the affiliate`});
					} else {
						this.list[id]['show_to_affiliate'] = !val;
						this.list = [...this.list];
					}
				} catch(e) {
					console.error(e);
					this.list[id]['show_to_affiliate'] = !val;
					this.list = [...this.list];
				}
			},
			toggleInlineEdit(id, uuid, type) {
					// console.log(id, uuid, type)
				this.editModeCount++;
					this.list[id][`original_${type}`] = this.list[id][type];
					this.list[id][`edit_mode_${type}`] = true;
					this.list = [...this.list];
			},
				async crearParamXYValue(id, uuid, type) {
						this.$apix.sendHttpRequest('PUT', `lead/param/clear/${type}`, { uuid })
								.then(res => {
										if(res[0]) {
												this.list[id][type] = null;
												this.list = [...this.list];
										}
								});
				},
			trafficSourceInlineUpdate(val, id, uuid, type) {
					this.saveInlineEdit(val, id, uuid, type).then(res => {
							this.list[id][type] = this.traffic_source_hash[val].t;
							this.fetchData(false, true);
					});
			},
			async saveInlineEdit(val, id, uuid, type) {
					if(val !== null) {
							if(!val) return this.$notify.warning({title: 'Validation Error', message: 'Value is empty!'})

							const result = await this.$apix.sendHttpRequest('PUT', `lead/param/${type}`, { uuid, val })

					} else {
							this.list[id][type] = this.list[id][`original_${type}`]
					}
					this.list[id][`edit_mode_${type}`] = false;
					this.list = [...this.list];
					this.editModeCount--;
			},

			addToReassignQue() {
				let arr = [];
				for(let e of this.multipleSelection) {
					if(!!e.phone && !!e.cid) {
						return this.$notify.warning({ message: 'Cannot use this action on mixed selections. Select only leads that failed', title: 'Mixed Types Selected' });
					}
					arr.push(e.uuid);
				}
				this.reassign_leads_que_arr = arr;

				this.current_action = 'multi-user'
				this.reassignQueModal = !this.reassignQueModal;
			},
			bulkFtd() {
				let arr = [];
				for(let e of this.multipleSelection) {
					if(!e.phone || !e.cid) {
						return this.$notify.warning({ message: 'Cannot use this action on mixed selections. Select only customers', title: 'Mixed Types Selected' });
					}
					if(e.ftd) {
						return this.$notify.warning({ message: 'Cannot toggle customers that already toggled FTD', title: 'FTD Already Toggled' });
					}
					arr.push(e.uuid);
				}
				let that = this;
				this.$confirm('Are you sure that you want to toggle FTD? This operation cannot be undone!', 'FTD Toggle Alert')
						.then(() => {
							try {
								this.block = true;
								that.$apix.sendHttpRequest('PUT', 'lead/toggle-ftd-bulk', { arr })
								that.$notify.success('Entry removed successfully');
								that.fetchData();
								that.block = false;
							} catch (err) {
								console.error('[ERROR] Search, mark ftd failed: ', err);
							}
						})
						.catch(err => {
							console.error('[ERROR] Search, mark ftd failed: ',err);
						});
			},
			bulkReassign() {
				let arr = [];
				for(let e of this.multipleSelection) {
					if(!!e.phone && !!e.cid) {
						return this.$notify.warning({ message: 'Cannot use this action on mixed selections or successful registrations. Select only leads that failed', title: 'Mixed Types Selected' });
					}
					arr.push(e.uuid);
				}
				this.reassign_leads_arr = arr;

				this.current_action = 'multi-user'
				this.reregisterModal = !this.reregisterModal;
			},
			bulkCopy() {
				let arr = [];
				for(let e of this.multipleSelection) {
					if(!!e.phone && !e.cid) {
						return this.$notify.warning({ message: 'Cannot use this action on mixed selections or failed registrations. Select only leads that succeeded', title: 'Mixed Types Selected' });
					}
					arr.push(e.uuid);
				}
				this.copy_leads_arr = arr;

				this.current_action = 'multi-user'
				this.copyModal = !this.copyModal;
			},
			bulkDelete() {

				let that = this;
				this.$confirm('Are you sure that you want to delete this entry? This operation cannot be undone!', 'Lead Remove Alert')
						.then(() => {
							try {
								let uuids = this.multipleSelection.map(e => e.uuid);
								this.$apix.sendHttpRequest('POST', 'lead/delete-bulk', { arr: uuids })
								that.$notify.success('Entry removed successfully');
								that.fetchData();
								this.block = false;
							} catch (err) {
								console.error('[ERROR] Search, lead delete failed: ',err);
							}
						})
						.catch(err => {
							console.error('[ERROR] Search, lead delete failed: ',err);
						});

			},
			handleSelectionChange(val) {
				this.block =  val.length > 0
				this.multipleSelection = val;
			},
			permit(minLvl) {
				return this.$store.state.ROLE_ID <= minLvl
			},
			exactRole(role) {
				return	+this.$store.state.ROLE_ID === role;
			},
			openSpreadsheetExportPormpt() {
					this.showExcelExportModal = true;
					this.spreadsheet_export = {
							columns: [],
							export_all_flag: true,
					}
			},
			downloadExcel() {
				if(!this.spreadsheet_export.export_all_flag && this.spreadsheet_export.columns.length === 0) return this.$notify.error({title: 'Validation Error', message: 'Columns to export are nop set! Either choose the columns or toggle on "Export All"'})
				setTimeout(() => {
					let $columns = !this.spreadsheet_export.export_all_flag ? { selected_columns: this.spreadsheet_export.columns } : {};
					let body = clearNulls(Object.assign({}, this.paginate, this.filters, $columns));
					this.$notify.info({title: 'Excel Download', message: 'Download started. Be patient'})

					let qs = '';
					qs += '?' + Object.keys(body).map(key => key + '=' + body[key]).join('&');
						this.showExcelExportModal = false;
					return new Promise((resolve, reject) => {
						this.$http.get('lead/list-xls' + qs, body, {responseType: 'arraybuffer', timeout: 0})
							.then(response => {
								let decode = new Buffer(response.body, 'base64')
								let blob = new Blob([decode], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
									url = window.URL.createObjectURL(blob)
								window.open(url)
								this.$notify.success({title: 'API Response',message: 'Download succeeded'})
							}).catch(e => {
								this.$notify.error({title: 'API Error', message: 'Could not download spreadsheet'})
						})
					});
				}, 200);
			},

			fetchData(ignore = false, ignoreInlineEdit = false) {
				if (this.refreshLeadFlag === 0) return;
				if (this.editModeCount > 0 && !ignoreInlineEdit) return;
				this.currentReferenceLead = {};

				if (ignore != 'true') this.busy = true;

				if(this.lockSearchFiltersCpt) this.$store.dispatch('setSearchFilters', this.filters)

				setTimeout(() => {
					let body = clearNulls(Object.assign({}, {...this.paginate}, {...this.filters}));
					this.$apix.sendHttpRequest('GET', 'lead/list', body)
							.then(res => {
								this.list = res.rows;
								this.count = res.count;
								this.ftds = res.ftds;
								this.conv_rate = res.conv_rate.toFixed(2);
								if (ignore != 'true') this.busy = false;
							})
							.catch(err => {
								console.error('[ERROR] Search, fetch data failed: ', err);
								if (ignore != 'true') this.busy = false;
							})
				}, 200)
			},
			refreshData() {
				this.currentReferenceLead = {};
				this.busy = true;
				setTimeout(() => {
					let body = clearNulls(Object.assign({}, {...this.paginate}, {...this.filters}));
					this.$apix.sendHttpRequest('GET', 'lead/list', body)
							.then(res => {
								this.list = res.rows;
								this.count = res.count;
								this.ftds = res.ftds;
								this.conv_rate = res.conv_rate.toFixed(2);
								this.busy = false;
							})
							.catch(err => {
								console.error('[ERROR] Search, fetch data failed: ', err);
								this.busy = false;
							})
				}, 200)
			},
			submitUpdateDetailsModal() {
				this.$refs.details_update_modal[this.bulkLeadEditFlag ? 'submitBulk' : 'submit']();
			},
			getFunnelFamiliesByBrand() {
				// this.fetchData();
				if(this.filters.source_app === 'FLOW' || this.filters.source_app === 'IFRAME') this.filters.funnel = '';
				if(this.filters.source_app === 'GOOGLE_SHEETS') this.filters.spreadsheet = '';
				this.filters.funnel_family = '';
				if(this.$store.state.ROLE_ID >= 4) this.fetchData();
				else this.$apix.sendHttpRequest('GET', 'funnel-family/options/brand/', {brands: this.filters.brand, type: this.filters.source_app === 'GOOGLE_SHEETS' ? 'Spreadsheet' : 'Funnel'})
					.then(res => {

						this.funnel_families_options = res;
						this.fetchData();
					})
			},
			funnelFamilySelected() {
				let topic = 'funnel';
				if(this.filters.source_app === 'GOOGLE_SHEETS') topic = 'spreadsheet';
				let arr = [];
				this.filters.funnel_family.forEach(e => {
					let curr = this.funnel_families_options.find(el => el.v === e);
					arr = [...arr, ...curr[this.filters.source_app === 'GOOGLE_SHEETS' ? 's' : 'f']]
				});
				this.filters.funnel = [];
				this.filters.spreadsheet = [];
				this.filters[topic] = arr
				// this.filters.funnel = this.funnel_families_options.f;
				this.fetchData(true);
			},
			geoIpGuess(ip) {
				this.currentGeoData = [];
				this.$apix.sendHttpRequest('GET', 'geo/by-ip', {ip})
					.then(res => {
						Object.entries(res).forEach((e, i) => {
							// e[1] = Object.entries(e[1])
							this.currentGeoData.push({topic: e[0], content: e[1]})
						});
						this.currentIP = ip;
						this.ipDialogShown = true;
					})
			},
				changeOrigin() {
					this.filters.funnel = '';
					this.filters.funnel_family = '';
					this.filters.spreadsheet = '';
					this.filters.campaign = '';
					this.filters.network_id = '';
					this.filters.product_sku = '';
					this.fetchData();
				},
			toggleModal(r = null) {
				this.showLeadModal = !this.showLeadModal

				if (r) {
					this.currentChosenLead = r;
					this.showMetaData(r.id, false);
				}
			},
			showParams(params, clearEmtpy = false) {
				this.currentParams = [];
				Object.entries(params).forEach((e, i) => {
					console.log({e})
					if ((!e[1] || e[1] === '') && clearEmtpy) return
					this.currentParams.push({t: e[0], v: e[1]})
				});
				this.paramsDialogShown = !this.paramsDialogShown;
			},
			populateAssignForm() {
				this.assign.first_name = this.currentChosenLead.first_name;
				this.assign.last_name = this.currentChosenLead.last_name;
				this.assign.phone_number = this.currentChosenLead.phone_number;
				this.assign.ip_addr = this.currentChosenLead.ip_addr;
				this.assign.country = this.currentChosenLead.country;
			},
				retryRegisterApi(lead) {
						const that = this;
						this.$confirm('API Network Registration', 'Are you sure that you want to retry to register this lead?')
								.then(() => {
										try {
												this.$apix.sendHttpRequest('POST', 'lead/re-register/network/' + lead.uuid).then(() => {
														that.$notify.success('Lead was successfully registered');
														this.fetchData();
												});
										} catch (err) {
												console.error('[API NETWORK](retryRegisterApi) Error: ', err);
										}
								})
								.catch(err => {
										console.error(err);
								});
				},
			toggleReregisterModal(r) {
				this.current_action = 'single-user'
				this.reregisterModal = !this.reregisterModal;
				this.currentChosenLead = r;
				this.showMetaData(r.id, false);
				this.populateAssignForm();

			},
			toggleCopyModal(r) {
				this.current_action = 'single-user'
				this.copyModal = !this.copyModal;
				this.currentChosenLead = r;
				this.showMetaData(r.id, false);
				this.populateAssignForm();
			},

			async reassignLeadToAnotherBrandBulk() {
				this.$refs.assign.validate(async valid => {
					if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
					this.busy = true;
					this.reassignBusy = true;
					let count = 1;
					for(let uuid of this.reassign_leads_arr) {
						try {
							const payload = {
								brand_id: this.assign.brand,
								funnel_id: this.assign.funnel,
								password: this.assign.password
							};
							if(this.copyFiledOverrideFlag) {
								if(this.current_action === "single-user" && this.assign.first_name !== '') payload.first_name = this.assign.first_name;
								if(this.current_action === "single-user" && this.assign.last_name !== '') payload.last_name = this.assign.last_name;
								if(this.current_action === "single-user" && this.assign.phone_number !== '') payload.phone_number = this.assign.phone_number;
								if(this.assign.country !== '') payload.country = this.assign.country;
								if(this.assign.ip_addr !== '') payload.ip_addr = this.assign.ip_addr;
							}
							let result = await this.$apix.sendHttpRequest('POST', 'lead/reassign/' + uuid, payload);
							this.$notify.success({message: 'Entry Re-Assignation successfully', title: `Success ${count++} of ${this.reassign_leads_arr.length}`});
						} catch (e) {
							this.$notify.error({message: 'Entry Re-Assignation failed', title: `Success ${count++} of ${this.reassign_leads_arr.length}`});
							console.error(e);
						}
					}
					this.fetchData();
					this.reassignBusy = false;
					this.reregisterModal = !this.reregisterModal;
					this.assign.brand = '';
					this.assign.funnel = '';
					this.assign.password = '';
					setTimeout(() => {
						// window.location.reload();  // @todo - why we need it?
						this.fetchData()
					}, 1000)
				});
			},
			async copyLeadToAnotherBrandBulk() {
				this.$refs.assign.validate(async valid => {
					if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
					this.busy = true;
					this.reassignBusy = true;
					let count = 1;
					for(let uuid of this.copy_leads_arr) {
						try {
							const payload = {
								brand_id: this.assign.brand,
								funnel_id: this.assign.funnel,
								password: this.assign.password
							};
							if(this.copyFiledOverrideFlag) {
								if(this.current_action === "single-user" && this.assign.first_name !== '') payload.first_name = this.assign.first_name;
								if(this.current_action === "single-user" && this.assign.last_name !== '') payload.last_name = this.assign.last_name;
								if(this.current_action === "single-user" && this.assign.phone_number !== '') payload.phone_number = this.assign.phone_number;
								if(this.assign.country !== '') payload.country = this.assign.country;
								if(this.assign.ip_addr !== '') payload.ip_addr = this.assign.ip_addr;
							}
							let result = await this.$apix.sendHttpRequest('POST', 'lead/reassign/' + uuid, payload);
							this.$notify.success({message: 'Entry Copy successfully', title: `Success ${count++} of ${this.copy_leads_arr.length}`});
						} catch (e) {
							this.$notify.error({message: 'Entry Copy failed', title: `Success ${count++} of ${this.copy_leads_arr.length}`});
							console.error(e);
						}
					}
					this.fetchData();
					this.reassignBusy = false;
					this.copyModal = !this.copyModal;
					this.assign.brand = '';
					this.assign.funnel = '';
					this.assign.password = '';
					setTimeout(() => {
						// window.location.reload();  // @todo - why we need it?
						this.fetchData()
					}, 1000)
				});

			},
			tableRowClassName(row) {
				// return row.row.busy ? 'warning-row' : ''
			},
			reassignLeadToAnotherBrand() {
				this.$refs.assign.validate(async valid => {
					if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
					this.busy = true;
					this.reassignBusy = true;
					const payload = {
						brand_id: this.assign.brand,
						funnel_id: this.assign.funnel,
						password: this.assign.password
					}
					if(this.copyFiledOverrideFlag) {
						if(this.current_action === "single-user" && this.assign.first_name !== '') payload.first_name = this.assign.first_name;
						if(this.current_action === "single-user" && this.assign.last_name !== '') payload.last_name = this.assign.last_name;
						if(this.current_action === "single-user" && this.assign.phone_number !== '') payload.phone_number = this.assign.phone_number;
						if(this.assign.country !== '') payload.country = this.assign.country;
						if(this.assign.ip_addr !== '') payload.ip_addr = this.assign.ip_addr;
					}
					this.$apix.sendHttpRequest('POST', 'lead/reassign/' + this.currentChosenLead.uuid, payload)
						.then(res => {
							this.$notify.success('Entry Re-Assignation successfully');
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
						.finally(() => {
							this.fetchData();
							this.reassignBusy = false;
							this.reregisterModal = !this.reregisterModal;
							this.assign.brand = '';
							this.assign.funnel = '';
							this.assign.password = '';
							setTimeout(() => {
								// window.location.reload();  // @todo - why we need it?
								this.fetchData()
							}, 1000)
						});
				});
			},
			copyLeadToAnotherBrand() {
				this.$refs.assign.validate(async valid => {
					if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
					this.busy = true;
					this.reassignBusy = true;
					const payload = {
						brand_id: this.assign.brand,
						funnel_id: this.assign.funnel,
						password: this.assign.password
					}
					if(this.copyFiledOverrideFlag) {
						if(this.current_action === "single-user" && this.assign.first_name !== '') payload.first_name = this.assign.first_name;
						if(this.current_action === "single-user" && this.assign.last_name !== '') payload.last_name = this.assign.last_name;
						if(this.current_action === "single-user" && this.assign.phone_number !== '') payload.phone_number = this.assign.phone_number;
						if(this.assign.country !== '') payload.country = this.assign.country;
						if(this.assign.ip_addr !== '') payload.ip_addr = this.assign.ip_addr;
					}
					this.$apix.sendHttpRequest('POST', 'lead/reassign/' + this.currentChosenLead.uuid, payload)
						.then(res => {
							this.$notify.success('Entry Copy successfully');
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
						.finally(() => {
							this.fetchData();
							this.reassignBusy = false;
							this.copyModal = !this.copyModal;
							this.assign.brand = '';
							this.assign.funnel = '';
							this.assign.password = '';
							setTimeout(() => {
								// window.location.reload();  // @todo - why we need it?
								this.fetchData()
							}, 1000)
						})
				});
			},

			async retryRegisterBulk() {
				this.busy = true;
				this.reassignBusy = true;
				let count = 1;
				for(let uuid of this.reassign_leads_arr) {
					try {
						let result = await this.$apix.sendHttpRequest('POST', 'lead/re-register/' + uuid, )
						this.$notify.success({message: 'Entry Re-Assignation successfully', title: `Success ${count++} of ${this.reassign_leads_arr.length}`});
					} catch (e) {
						this.$notify.error({message: 'Entry Re-Assignation failed', title: `Success ${count++} of ${this.reassign_leads_arr.length}`});
						console.error(e);
					}
				}
				this.fetchData();
				this.reassignBusy = false;
				this.reregisterModal = !this.reregisterModal;
				this.assign.brand = '';
				this.assign.funnel = '';
				this.assign.password = '';
			},

			addLeadsToQue(type) {
				let p;
				//const $grouping = this.assign.grouping ? {grouping: this.assign.grouping} : {};
				if(type === 'reassign')
					p = this.$apix.sendHttpRequest('POST', 'lead/add-to-que', {
						list: this.reassign_leads_que_arr,
						brand_id: this.assign.brand,
						funnel_id: this.assign.funnel,
						password: this.assign.password,
						interval: this.executionInterval,
						type,
						//...$grouping
					});
				else if (type === 're-register')
					p = this.$apix.sendHttpRequest('POST', 'lead/add-to-que', {
						list: this.reassign_leads_que_arr,
						password: this.assign.password,
						interval: this.executionInterval,
						type,
						//...$grouping
					});
				p.then(res => {
					this.$notify.success({title: 'API Response', message: 'Successfully added the leads to the queue'})
					this.reassignQueModal=!this.reassignQueModal;
					this.fetchData();
					// if(res.enqueued && !res.msg) {
					// 	this.$notify.success({title: 'Assign Success', message: `Successfully assigned ${res.enqueued.length} leads. IDs: ${res.enqueued.map(e => e.id)}`});
					// } else if(res.enqueued && res.msg) {
					// 	this.$notify.warning({title: 'Assign Success with warning', message: `Some leads were not assigned. ${res.msg}`})
					// } else {
					// 	this.$notify.error({title: 'Assign Error', message: 'There was an issue assigning the lead'});
					// }
				})
						.catch(err => {
							console.error(err);
							this.reassignQueModal=!this.reassignQueModal
						})
			},

			retryRegister(uuid) {
				this.busy = true;
				this.reassignBusy = true;
				this.$apix.sendHttpRequest('POST', 'lead/re-register/' + uuid, )
						.then(res => {
							this.$notify.success('Entry Re-Registered successfully');
							this.fetchData();
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
				.finally(() => {
					this.reassignBusy = false;
					this.reregisterModal = !this.reregisterModal;
					this.assign.brand = '';
					this.assign.funnel = '';
					this.assign.password = '';
				})
			},

			validationsComments() {
				this.errors.c_topic = { m: '', s: false };
				this.errors.c_content = { m: '', s: false };

				let error;

				if(this.commentsForm.topic === '') {
					this.errors.c_topic = { s: true, m: 'Topic is mandatory!' };
					error = true;
				}

				if(this.commentsForm.content === '') {
					this.errors.c_content = { s: true, m: 'Content is mandatory!' };
					error = true;
				}

				return !error;
			},

			showComments(lead) {
				this.showCommentsModal = !this.showCommentsModal;
				this.currentComment = { list: [], count: 0 };
				this.commentsForm = { topic: '', content: '' }
				this.currentChosenLead = lead;
				this.currentCommentPaginate.page = 1;
				this.currentCommentPaginate.keyword = '';
				this.$apix.sendHttpRequest('GET', 'comments/list/' + lead.uuid,  this.currentCommentPaginate )
					.then(res => {
						this.currentComment.list = res.rows
						this.currentComment.count = res.count
					})
					.catch(err => {
						console.error(err);
					})
			},

			load(lead) {
				if(this.currentCommentPaginate.page * this.currentCommentPaginate.limit > this.currentComment.count) return;
				this.currentCommentPaginate.page++;
				this.$apix.sendHttpRequest('GET', 'comments/list/' + lead.uuid,  this.currentCommentPaginate )
						.then(res => {
							this.currentComment.list = [...this.currentComment.list,...res.rows]
						})
						.catch(err => {
							console.error(err);
						})
			},

			saveComment() {
				if(!this.validationsComments()) return this.$notify.error({title: 'Validation errors', message: 'Please, fix the errors in the red boxes'});
				this.$apix.sendHttpRequest('POST', 'comments/' + this.currentChosenLead.uuid, this.commentsForm)
					.then(res => {
						this.$notify.success({message: 'Comment successfully added', title: 'API Response'});
						this.showCommentsModal = false;
					})
			},

			loadReferenceLead(id) {
				this.referenceLeadModal = !this.referenceLeadModal;
				this.currentReferenceLead = {};
				this.$apix.sendHttpRequest('GET', 'lead/reference-lead/' + id)
					.then(res => {
						try {
							this.currentReferenceLead = Object.entries(res);
						} catch (e) {
							console.error('[ERROR] currentReferenceLead - could not build an array ')
							this.currentReferenceLead = [];
						}
					})
				.catch(err => {
					console.error(err);
				})
			},

			removeEntity(id) {
				let that = this;
				this.$confirm('Are you sure that you want to delete this entry? This operation cannot be undone!', 'Lead Remove Alert')
						.then(() => {
							try {
								this.$apix.sendHttpRequest('DELETE', 'lead/delete', {id})
								that.$notify.success('Entry removed successfully');
								that.fetchData();
							} catch (err) {
								console.error(err);
							}
						})
						.catch(err => {
							console.error(err);
						});
			},
				showDetailsUpdate(type, lead){
					if(type === 'single') {
						this.currentChosenLead = lead;
						this.showDetailsUpdateModal = true;
						this.bulkLeadEditFlag = false;
					} else if (type === 'bulk') {
						this.showDetailsUpdateModal = true;
						this.bulkLeadEditFlag = true;
					}
				},
				updateDetailsSuccess(code) {
					this.showDetailsUpdateModal=false;
					this.bulkLeadEditFlag = false;
					this.currentChosenLead = null;
					if(code) this.fetchData()
				},
			showMetaData(id, toggleModal = true) {
				this.currentMeta = [];
				this.$apix.sendHttpRequest('GET', 'lead/meta', {id})
					.then(res => {
						this.currentMeta = res;
						if (toggleModal) this.metaDialogShown = !this.metaDialogShown;
					})
					.catch(err => {
						console.error(err);
					})

			},
			selectStatus(event) {
				if(this.filters.original_status.length === 2 && (this.filters.original_status.includes('<') || this.filters.original_status.includes('>') || this.filters.original_status.includes('all'))) {
					this.filters.original_status.shift()
				}
				// Clear the filters that are related to the origin
				this.filters.language = '';
				this.filters.funnel = '';
				this.filters.funnel_family = '';
				this.filters.spreadsheet = '';
				this.filters.campaign = '';

				this.fetchData();
			},
			openLink(url) {
				window.open(url, "_blank")
			},
			getBrokerData() {
				this.currentBrokerData = [];
			},
			randId() {
				return 'id_' + Math.random().toString(36).replace('0.','u9iter5' || '');
			},
			toggleFtd(uuid) {
				let that = this;
				this.$confirm('Are you sure that you want to set lead as FTD? This operation cannot be undone!', 'Lead Remove Alert')
						.then(() => {
							try {
								that.$apix.sendHttpRequest('PUT', 'lead/toggle-ftd/' + uuid)
										.then(res => {
											that.$notify.success({title: 'API Response', message: 'FTD Accepted'});
											that.fetchData();
										})
							} catch (err) {
								console.error(err);
							}
						})
						.catch(err => {
							console.error(err);
						});


			},
			toggleTable() {
				this.showColumnSelectModal = !this.showColumnSelectModal
			},
			columnSelectedHook(title) {
				this.$store.dispatch('setTableColumns', this.selectedColumns)
			},
			clearFilters() {
				this.$store.dispatch('removeSearchFilters');
				this.filters = JSON.parse(JSON.stringify($originalFilters));
				this.fetchData();
			},
			permitColumn(columns, strict = true) {
				if(this.$store.getters.ACL === 'all') return true;
				let permittedCount = columns.length;
				for(const col of columns) {
					if(!this.$store.getters.ACL[col])
						if(strict)
							return false;
						else
							permittedCount--;
					if(permittedCount===0) return false;
				}
				return true;
			},
			setColumns() {
				let allowed = this.$store.getters.ACL;

				if(Object.keys(this.selectedColumnsHash).length === 0) {
					this.originalColumns = {}
					if(allowed === 'all' || allowed.short_uuid || allowed.short_uuid) 																this.originalColumns['UUID']			 					=	false;
					if(allowed === 'all' || allowed.first_name || allowed.last_name) 																	this.originalColumns['Full Name'] 					=	true;
					if(allowed === 'all' || allowed.cid) 																															this.originalColumns['CID'] 								=	true;
					if(allowed === 'all' || allowed.email) 																														this.originalColumns['E-Mail'] 							=	true;
					if(allowed === 'all' || allowed.phone) 																														this.originalColumns['Phone'] 							=	true;
					if(allowed === 'all' || allowed.country) 																													this.originalColumns['Country'] 						=	true;
					if(allowed === 'all' || allowed.date_created && allowed.date_only_created_raw) 										this.originalColumns['Timestamp'] 					=	true;
					if(allowed === 'all' || allowed.ftd) 																															this.originalColumns['FTD'] 								=	true;
					if(allowed === 'all' || allowed.ftd_date) 																												this.originalColumns['FTD Date'] 						=	this.$store.state.ROLE_ID === '4';
					if(allowed === 'all' || allowed.network_campaign || allowed.funnel || allowed.spreadsheet) 				this.originalColumns['Language'] 						=	true;
					if(allowed === 'all' || allowed.brand) 																														this.originalColumns['Brand'] 							=	true;
					if(this.permit(2) && (allowed === 'all' || allowed.brand_global))													this.originalColumns['Brand Global'] 				=	false;
					if(this.csbo && this.permit(3) && (allowed === 'all' || allowed.brand_owner)) 				  		this.originalColumns['Brand Owner'] 				=	true;
					if(allowed === 'all' || allowed.funnel) 																													this.originalColumns['Funnel'] 							=	true;
					if(allowed === 'all' || allowed.spreadsheet) 																											this.originalColumns['Spreadsheet'] 				=	false;
					if(allowed === 'all' || allowed.network_campaign) 																								this.originalColumns['Campaign'] 						=	false;
					if(allowed === 'all' || allowed.original_campaign) 																								this.originalColumns['Original Campaign'] 	=	false;
					if(allowed === 'all' || allowed.affiliate_id) 																											 this.originalColumns['Affiliate'] 	=	false;
					if(allowed === 'all' || (allowed.integration && allowed.network_campaign) || (allowed.integration && allowed.funnel) || (allowed.integration && allowed.spreadsheet))
						this.originalColumns['Integration'] 	=	false;
					if(allowed === 'all' || allowed.ip_addr) 																													this.originalColumns['IPv4'] 								=	false;
					if(allowed === 'all' || allowed.comment) 																													this.originalColumns['Comments'] 						=	false;
					if(allowed === 'all' || allowed.jc_grp_id) 																												this.originalColumns['JC'] 									=	false;
					if(allowed === 'all' || allowed.conv_status) 																											this.originalColumns['Conv. Status'] 				=	false;
					if(allowed === 'all' || allowed.param_x) 																													this.originalColumns['Param X'] 						=	false;
					if(allowed === 'all' || allowed.param_y) 																													this.originalColumns['Param Y'] 						=	false;
					if(allowed === 'all' || allowed.failed_reason) 																										this.originalColumns['Failed Reason'] 			=	true;
					if(allowed === 'all' || allowed.product_name ) 																										this.originalColumns['Product'] 						= false;
					if(allowed === 'all' || allowed.network) 																													this.originalColumns['Network'] 						= false;

					if(allowed === 'all' || allowed.resolved_country_iso)																							this.originalColumns['Resolved Country'] 		=	false;
					if(allowed === 'all' || allowed.resolved_region_id && allowed.resolved_region_name)								this.originalColumns['Resolved Region'] 		=	false;
					if(allowed === 'all' || allowed.resolved_city_id && allowed.resolved_city_name)										this.originalColumns['Resolved City'] 			=	false;

								//if(this.permit(2) && (allowed === 'all' || allowed.show_to_affiliate)) 										this.originalColumns['Show/Hide'] 					=	false;
					if(this.cspa && this.permit(2) && (allowed === 'all' || allowed.params_affiliate_entry)) 	this.originalColumns['Affiliate (Param)'] 	=	false;
					if(this.permit(4) && (allowed === 'all' || allowed.funnel_family_name)) 										this.originalColumns['Funnel Family'] 			= false;
					if(this.permit(4) && (allowed === 'all' || allowed.original_funnel_family_id)) 					  this.originalColumns['Original Funnel Family'] = false;
					if(this.permit(4) && (allowed === 'all' || allowed.real_funnel_name)) 											this.originalColumns['Real Funnel Name'] 		= true;
					if(this.permit(4) && (allowed === 'all' || allowed.original_status)) 											this.originalColumns['Status - Broker']			= true;
					if(this.permit(4) && (allowed === 'all' || allowed.mapped_status)) 												this.originalColumns['Status - Mapped']			= true;
					if(this.permit(4) && (allowed === 'all' || allowed.traffic_source && allowed.real_source)) this.originalColumns['Traffic Source'] 			= this.$store.state.ROLE_ID === '4' || this.permit(1);
					if(this.permit(1) && (allowed === 'all' || allowed.visit_id)) 															this.originalColumns['Visit ID'] 						= false;
					if(this.permit(2) && (allowed === 'all' || allowed.click_id)) 															this.originalColumns['ClickID'] 						= false;
					if(this.permit(2) && (allowed === 'all' || allowed.params)) 																this.originalColumns['Params'] 							= this.permit(2) ;
					if(this.csmp && this.permit(2)) 					 																									this.originalColumns['Mapped Params'] 			= false ;
					this.originalColumns['Actions'] =	false;
				} else {
					this.originalColumns = {}
					if(allowed === 'all' || allowed.short_uuid || allowed.short_uuid) 																	this.originalColumns['UUID'] 							= this.selectedColumnsHash['UUID'];
					if(allowed === 'all' || allowed.first_name || allowed.last_name) 																		this.originalColumns['Full Name'] 				= this.selectedColumnsHash['Full Name'];
					if(allowed === 'all' || allowed.cid) 																																this.originalColumns['CID'] 							= this.selectedColumnsHash['CID'];
					if(allowed === 'all' || allowed.email) 																															this.originalColumns['E-Mail'] 						= this.selectedColumnsHash['E-Mail'];
					if(allowed === 'all' || allowed.phone) 																															this.originalColumns['Phone'] 						= this.selectedColumnsHash['Phone'];
					if(allowed === 'all' || allowed.country) 																														this.originalColumns['Country'] 					= this.selectedColumnsHash['Country'];
					if(allowed === 'all' || allowed.date_created && allowed.date_only_created_raw) 											this.originalColumns['Timestamp'] 				= this.selectedColumnsHash['Timestamp'];
					if(allowed === 'all' || allowed.ftd) 																																this.originalColumns['FTD'] 							= this.selectedColumnsHash['FTD'];
					if(allowed === 'all' || allowed.ftd_date) 																													this.originalColumns['FTD Date'] 					= this.selectedColumnsHash['FTD Date'];
					if(allowed === 'all' || allowed.network_campaign || allowed.funnel || allowed.spreadsheet) 					this.originalColumns['Language'] 					= this.selectedColumnsHash['Language'];
					if(allowed === 'all' || allowed.brand) 																															this.originalColumns['Brand'] 						= this.selectedColumnsHash['Brand'];
					if(this.permit(2) && (allowed === 'all' || allowed.global_name)) 														this.originalColumns['Brand Global'] 			= this.selectedColumnsHash['Brand Global'];
					if(this.csbo && this.permit(3) && (allowed === 'all' || allowed.brand_owner)) 								this.originalColumns['Brand Owner'] 			= this.selectedColumnsHash['Brand Owner'];
					if(allowed === 'all' || allowed.funnel) 																														this.originalColumns['Funnel'] 						= this.selectedColumnsHash['Funnel'];
					if(allowed === 'all' || allowed.spreadsheet) 																												this.originalColumns['Spreadsheet'] 			= this.selectedColumnsHash['Spreadsheet'];
					if(allowed === 'all' || allowed.network_campaign) 																									this.originalColumns['Campaign'] 					= this.selectedColumnsHash['Campaign'];
					if(allowed === 'all' || allowed.original_campaign) 																									this.originalColumns['Original Campaign'] = this.selectedColumnsHash['Original Campaign'];
					if(allowed === 'all' || allowed.affiliate_id) 																												 this.originalColumns['Affiliate']         = this.selectedColumnsHash['Affiliate'];
					if(allowed === 'all' || (allowed.integration && allowed.network_campaign) || (allowed.integration && allowed.funnel) || (allowed.integration && allowed.spreadsheet))
						this.originalColumns['Integration']	= this.selectedColumnsHash['Integration'];
					if(allowed === 'all' || allowed.ip_addr) 																														this.originalColumns['IPv4'] 							= this.selectedColumnsHash['IPv4'];
					if(allowed === 'all' || allowed.comment) 																														this.originalColumns['Comments'] 					= this.selectedColumnsHash['Comments'];
					if(allowed === 'all' || allowed.jc_grp_id) 																													this.originalColumns['JC'] 								= this.selectedColumnsHash['JC'];
					if(allowed === 'all' || allowed.conv_status) 																												this.originalColumns['Conv. Status'] 			= this.selectedColumnsHash['Conv. Status'];
					if(allowed === 'all' || allowed.param_x) 																														this.originalColumns['Param X'] 					= this.selectedColumnsHash['Param X'];
					if(allowed === 'all' || allowed.param_y) 																														this.originalColumns['Param Y'] 					= this.selectedColumnsHash['Param Y'];
					if(allowed === 'all' || allowed.failed_reason) 																											this.originalColumns['Failed Reason'] 		= this.selectedColumnsHash['Failed Reason'];
					if(allowed === 'all' || allowed.product_name) 																											this.originalColumns['Product'] 					= this.selectedColumnsHash['Product'];
					if(allowed === 'all' || allowed.network) 																														this.originalColumns['Network'] 					= this.selectedColumnsHash['Network'];

					if(allowed === 'all' || allowed.resolved_country_iso)																								this.originalColumns['Resolved Country'] 	= this.selectedColumnsHash['Resolved Country'];
					if(allowed === 'all' || allowed.resolved_region_id && allowed.resolved_region_name) 			  				this.originalColumns['Resolved Region'] 	= this.selectedColumnsHash['Resolved Region'];
					if(allowed === 'all' || allowed.resolved_city_id && allowed.resolved_city_name) 										this.originalColumns['Resolved City'] 		= this.selectedColumnsHash['Resolved City'];

					if(this.cspa && this.permit(3) && (allowed === 'all' || allowed.params_affiliate_entry)) 		this.originalColumns['Affiliate (Param)']	= this.selectedColumnsHash['Affiliate (Param)'];
					//if(this.permit(2) && (allowed === 'all' || allowed.show_to_affiliate)) 														this.originalColumns['Show/Hide'] 				= this.selectedColumnsHash['Show/Hide'];
					if(this.permit(4) && (allowed === 'all' || allowed.funnel_family_name))											this.originalColumns['Funnel Family'] 		= this.selectedColumnsHash['Funnel Family'];
					if(this.permit(4) && (allowed === 'all' || allowed.original_funnel_family_id))						    this.originalColumns['Original Funnel Family'] = this.selectedColumnsHash['Original Funnel Family'];
					if(this.permit(4) && (allowed === 'all' || allowed.real_funnel_name))												this.originalColumns['Real Funnel Name'] 	= this.selectedColumnsHash['Real Funnel Name'];
					if(this.permit(4) && (allowed === 'all' || allowed.original_status))													this.originalColumns['Status - Broker'] 	= this.selectedColumnsHash['Status - Broker'];
					if(this.permit(4) && (allowed === 'all' || allowed.mapped_status))														this.originalColumns['Status - Mapped'] 	= this.selectedColumnsHash['Status - Mapped'];
					if(this.permit(4) && (allowed === 'all' || allowed.traffic_source && allowed.real_source)) 	this.originalColumns['Traffic Source'] 		= this.selectedColumnsHash['Traffic Source'];
					if(this.permit(1) && (allowed === 'all' || allowed.visit_id)) 																this.originalColumns['Visit ID'] 					= this.selectedColumnsHash['Visit ID'];
					if(this.permit(2) && (allowed === 'all' || allowed.click_id))																this.originalColumns['ClickID'] 					= this.selectedColumnsHash['JC'];
					if(this.permit(2) && (allowed === 'all' || allowed.params)) 																	this.originalColumns['Params'] 						= this.selectedColumnsHash['Params'];
					if(this.csmp && this.permit(2)) 																															this.originalColumns['Mapped Params'] 		= this.selectedColumnsHash['Mapped Params'];
				}
				this.originalColumns['Actions'] = this.selectedColumnsHash['Actions'];
				this.selectedColumns = { ...this.originalColumns };
			}
		},

	}
</script>
